import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../modules/Genric componant/Pagination';
import permission from '../../../modules/Genric componant/permissions/permission';
import Dummy from "../../../../loading_assests/dummy.png";
import moment from 'moment';
import { GetMembers } from '../../../modules/finance/modules/invoice/component/redux/invoiceCRUD';
import * as invoice from "../../../modules/finance/modules/invoice/component/redux/invoiceRedux";


const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);


const InvoiceModalData = (selectedDate: any) => {

  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "iReceiptID", // Default sorting column
    direction: "desc", // Default sorting direction
  });
  const dispatch = useDispatch();
  const invoices: any = useSelector(
    (state: any) => state?.invoices?.invoices?.data
  );


  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  const activeBranchID = ActiveBranch.iBranchID;



  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("Invoices");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });


  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const [baseURL, setBaseURL] = useState<any>([]);



  const [checkedRows, setCheckedRows] = useState<any>([]);


  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filteredData, setFilteredData] = useState<any>([]);
  const [TBLData, setTBLData] = useState<any>([]);


  const [dateFormat, setdateFormat] = useState<any>(ActiveBranch?.idateFormate);
  const [isLoading, setIsLoading] = useState<boolean>(false);

 const stardDate = selectedDate.selectedDate.startDate;
 const endDate = selectedDate.selectedDate.endDate;
  let initialState = {
    branchID: activeBranchID,
    status: "",
    page_limit: pageLimit,
    // page_no: 1,
    paymentMode: "",
    membershipType: "",
    employeeID: "",
    occuptionID: "",
    gender: "",
    maritalStatus: "",
    batchID: "",
    packageUUID: "",
    packageGroupUUID: "",
    search: "",
    start_date: stardDate,
    end_date: endDate,
    order_key: " iReceiptID",
    order_value: "desc",
  };


  const [filterValues, setFilterValues] = useState(initialState);




  const firstColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERSHIP.MEMBER",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.AMOUNT",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.PAYMENTMODE",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.INVOICENO",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.RECIEVER",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.INVOICES.PAYMENTDATE",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.INVOICES.NEXTPAYMENTDATE",
  })}`;

  const tenthColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;


  const handleExports = () => {
    const tableRows = invoices?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [firstColumnTitle]: ` ${element.memberName} ${element.MemberCountryCode}- ${element.memnerMobileNo}`,
        [thirdColumnTitle]:
          element.packageName + " " + `(${element.eEnrol_Type})`,
        [fourthColumnTitle]: element.amount,
        [fifthColumnTitle]: element.paymentMode,
        [sixthColumnTitle]: element?.VInvoiceNO,
        [seventhColumnTitle]: element.receiveEemployeeName,
        [eigthColumnTitle]:
          element?.paymentDate +
          `(${moment(element.paymentTime, "HH:mm").format("hh:mm A")}})`,
        [ninthColumnTitle]: element?.dtNext_Payment,
      };
    });

    setTBLData(tableRows);
  };


  const getMembers = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetMembers({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data, "data");
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(invoice.actions.GetInvoices(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);


  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setFilteredData(invoices?.data);
    setBaseURL(invoices?.imageBaseURL);
    handleExports();
  }, [invoices?.data]);



  const handleRowCheckboxChange = (rowId: any) => {
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    // console.log("page limit event", values);
    setPage(1);
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  return (
    <>
      <div className='w-25'>
        <span
          className="min-w-40px mb-3 d-flex align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card-body py-3">
        {/* {isLoading && <Loading />} */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="memberName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "memberName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "memberName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "memberName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirdColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="amount"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "amount" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "amount" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "amount" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="paymentMode"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "paymentMode" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "paymentMode" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "paymentMode" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="VInvoiceNO"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "VInvoiceNO" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "VInvoiceNO" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "VInvoiceNO" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="receiveEemployeeName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "receiveEemployeeName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "receiveEemployeeName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "receiveEemployeeName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="paymentDate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "paymentDate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "paymentDate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "paymentDate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtNext_Payment"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "dtNext_Payment" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtNext_Payment" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtNext_Payment" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredData && filteredData?.length === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  // setUUID(element.iEnrolUUID);
                  const isChecked = checkedRows.includes(
                    element.iReceiptUUID
                  );
                  // console.log("uidofmember", element);
                  return (
                    <>
                      <tr key={index} className="fs-5">
                        <td data-label={firstColumnTitle}>
                          <div className="d-flex align-items-center border-0 ">
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                              {/* <a href="../../demo1/dist/apps/user-management/users/view.html"> */}
                              <div className="symbol-label">
                                <img
                                  src={
                                    element?.vPhoto
                                      ? `${baseURL}${element.vPhoto}`
                                      : Dummy
                                  }
                                  width={50}
                                  height={50}
                                // className="w-100"
                                />
                              </div>
                              {/* </a> */}
                            </div>
                            <div className="d-flex flex-column ">
                              {`${element.memberName}`}
                              <a
                                href={`tel:${element.MemberCountryCode}-${element.memnerMobileNo}`}
                              >
                                {`(${element.MemberCountryCode}-${element.memnerMobileNo})`}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td data-label={thirdColumnTitle}>
                          {`${element.packageName} (${element.eEnrol_Type})`}{" "}
                        </td>
                        <td
                          data-label={fourthColumnTitle}
                        >{`${element?.amount} `}</td>
                        <td data-label={fifthColumnTitle}>
                          {" "}
                          {element.paymentMode}
                        </td>
                        <td data-label={sixthColumnTitle}>
                          {element.VInvoiceNO}
                        </td>
                        <td data-label={seventhColumnTitle}>
                          {element.receiveEemployeeName}
                        </td>
                        <td data-label={eigthColumnTitle}>
                          {moment(element.paymentDate).format(dateFormat)} (
                          {moment(element.paymentTime, "HH:mm").format(
                            "hh:mm A"
                          )}
                          )
                        </td>
                        <td data-label={ninthColumnTitle}>
                          {moment(element.dtNext_Payment).format(dateFormat)}
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="memberName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "memberName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "memberName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "memberName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirdColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="amount"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "amount" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "amount" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "amount" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="paymentMode"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "paymentMode" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "paymentMode" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "paymentMode" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="VInvoiceNO"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "VInvoiceNO" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "VInvoiceNO" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "VInvoiceNO" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="receiveEemployeeName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "receiveEemployeeName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "receiveEemployeeName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "receiveEemployeeName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="paymentDate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "paymentDate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "paymentDate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "paymentDate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtNext_Payment"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "dtNext_Payment" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtNext_Payment" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtNext_Payment" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin:: pagination  */}

        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  )
}

export default InvoiceModalData