import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from "react-intl";
import permission from '../../../modules/Genric componant/permissions/permission';
import moment from 'moment';
import Pagination from '../../../modules/Genric componant/Pagination';
import { GetMembers, activeUserList } from '../../../modules/memberships/modules/expire-package/component/redux/renewalCRUD';
import { useDispatch, useSelector } from 'react-redux';
import * as Expires from "../../../modules/memberships/modules/expire-package/component/redux/renewalRedux";
import RenewalModal from '../../../modules/members/component/modal/RenewalModal';
import { ActiveBatchList, ActivePackageList } from '../../../modules/members/redux/MembersCRUD';

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);


const ExpireModalData = (selectedDate: any) => {
  const tableRef = useRef(null);
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "asc", // Default sorting direction
  });
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  const activeBranchID = ActiveBranch.iBranchID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const dispatch = useDispatch();
  const expires: any = useSelector(
    (state: any) => state?.Expires?.Expires?.data
  );
  const Access = permission("remaining-balance");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });
  const [batchData, setBatchData] = useState<any>();

  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);

  // const [renewalShow, setRenewalShow] = useState<boolean>(false);
  // const [memberUID, setMemberUID] = useState<any>();

  // const start = moment().format(dateFormat);
  // const end = moment(start).add(10, "days").format(dateFormat);
  const [activePackageList, setActivePackageList] = useState<any>([]);

  const [activeBatchList, setActiveBatchList] = useState<any>([]);

  const [memberUID, setMemberUID] = useState<any>();
  const [renewalShow, setRenewalShow] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [TBLData, setTBLData] = useState<any>([]);
  const [baseURL, setBaseURL] = useState<any>([]);
  const [activeUser, setActiveUser] = useState<any>([]);

  //define page limit
  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const handleSort = (Event: React.MouseEvent<HTMLElement>) => {
    setIsSort(true);
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  const secondColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.MEMBER",
  })}`;
  const ImageColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.MOBILE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "COMMON.PAYABLE",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.DUOAMT",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.BATCH",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.STARTDATE",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENDDATE",
  })}`;

  // const tenthColumnTitle = `${intl.formatMessage({
  //   id: "COMMON.STATUS",
  // })}`;
  const eleventhColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;
  const twelthColumnTitle = `${intl.formatMessage({
    id: "MODULE.EMPLOYEE.joinDate",
  })}`;
  const thirteenthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENROLTYPE",
  })}`;


  const startDate = selectedDate.selectedDate.startDate
  const endDate = selectedDate.selectedDate.endDate

  // console.log(startDate)
  // console.log(endDate)
  let initialState = {
    branchID: activeBranchID,
    status: "",
    page_limit: pageLimit,
    // page_no: 1,
    page_no: 1,
    spotUUID: "",
    EnrolType: "",
    employeeID: "",
    occuptionID: "",
    gender: "",
    martitalStatus: "",
    batchID: "",
    packageID: "",
    packageGroupID: "",
    search: "",
    date_type: "expire",
    start_date: startDate,
    end_date: endDate,
    order_key: "iEnrolID",
    order_value: "desc",
  };
  const [filterValues, setFilterValues] = useState(initialState);


  const getMembers = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetMembers({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data, "data");
        if (data?.status !== 200) {
          setFilteredData([]);
        } else {
          dispatch(Expires.actions.GetExpires(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  const getActiveBatchList = async (branchUUID: any) => {
    const response = await ActiveBatchList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveBatchList(response?.data?.data);
    setBatchData(response?.data?.data);
  };
  //end get active batch list

  //start get active package list
  const getActivePackageList = async (branchUUID: any) => {
    const response = await ActivePackageList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActivePackageList(response?.data?.data);
  };

  const handleExports = () => {
    const tableRows = expires?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [secondColumnTitle]: ` ${element.name} ${element.countryCode}- ${element.mobileNo}`,
        [thirdColumnTitle]: element.eEnrol_Type,
        [fourthColumnTitle]: element.packageName,
        [fifthColumnTitle]: element?.payableFee,
        [sixthColumnTitle]: element?.dueAmt,
        [seventhColumnTitle]:
          element.batchName &&
          `${element?.batchName} (${element.batchStartTime}-${element.batchEndTime})-${element.employeeName} `,
        [twelthColumnTitle]: element?.dtJoin,
        [eigthColumnTitle]: element?.dtStartingdate,
        [ninthColumnTitle]: element?.dtExpirydate,
      };
    });

    setTBLData(tableRows);
  };

  useEffect(() => {
    getActiveUserList(ActiveBranchUUID);
    getActivePackageList(ActiveBranchUUID);
    getActiveBatchList(ActiveBranchUUID);
  }, []);
  const getActiveUserList = async (branchUUID: any) => {
    const response = await activeUserList(branchUUID);
    setActiveUser(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setFilteredData(expires?.data);
    setBaseURL(expires?.imageBaseURL);
    handleExports();
  }, [expires?.data]);
  return (
    <>
      <div className='w-25'>
        <span
          className="min-w-40px mb-3 d-flex align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>

      <RenewalModal
        renewalShow={renewalShow}
        setRenewalShow={setRenewalShow}
        memberUID={memberUID}
        activePackageList={activePackageList}
        activeBatchList={activeBatchList}
      />

      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table
            className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
            ref={tableRef}
          >
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {secondColumnTitle}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  {" "}
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eEnrol_Type"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirteenthColumnTitle}
                    {sortConfig.key !== "eEnrol_Type" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="payableFee"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "payableFee" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "payableFee" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "payableFee" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dueAmt"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "dueAmt" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dueAmt" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dueAmt" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="batchName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "batchName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtJoin"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {twelthColumnTitle}
                    {sortConfig.key !== "dtJoin" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtStartingdate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "dtStartingdate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtExpirydate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "dtExpirydate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  {eleventhColumnTitle}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredData && filteredData?.length === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  // setUUID(element.iEnrolUUID);
                  const isChecked = checkedRows.includes(element.iEnrolUUID);
                  // console.log("uidofmember", element);
                  return (
                    <>
                      <tr key={index} className="fs-5">
                        <td data-label={secondColumnTitle}>
                          <div className="d-flex align-items-center border-0 ">
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                              {/* <a href="../../demo1/dist/apps/user-management/users/view.html"> */}
                              <div className="symbol-label">
                                {element.vPhoto && (
                                  <img
                                    src={`${baseURL}${element.vPhoto}`}
                                    width={50}
                                    height={50}
                                  // className="w-100"
                                  />
                                )}
                              </div>
                              {/* </a> */}
                            </div>

                            <div className="d-flex flex-column ">
                              {element.name}

                              <span>
                                <a
                                  href={`tel:${element.countryCode + element.mobileNo
                                    }`}
                                >
                                  ( {element.countryCode}-{element.mobileNo})
                                </a>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td data-label={thirdColumnTitle}>
                          {element.eEnrol_Type}
                        </td>
                        <td data-label={fourthColumnTitle}>
                          {element.packageName}
                        </td>
                        <td data-label={fifthColumnTitle}>
                          {element.payableFee}
                        </td>
                        <td data-label={sixthColumnTitle}>
                          {element.dueAmt}
                        </td>
                        <td data-label={seventhColumnTitle}>
                          {" "}
                          {element.batchName &&
                            `${element?.batchName} (${element.batchStartTime}-${element.batchEndTime})-${element.employeeName} `}
                        </td>
                        <td data-label={thirteenthColumnTitle}>
                          {moment(element?.dtJoin).format(dateFormat)}
                        </td>
                        <td data-label={eigthColumnTitle}>
                          {moment(element?.dtStartingdate).format(dateFormat)}
                        </td>
                        <td data-label={ninthColumnTitle}>
                          {moment(element?.dtExpirydate).format(dateFormat)}
                        </td>
                        {/* <td> {StatusBadge(element.eStatus)}</td> */}

                        <td data-label={eleventhColumnTitle}>
                          <div className="d-flex flex-shrink-0 ">
                            <a
                              href="#g"
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Renewal Packages"
                              onClick={() => {
                                setRenewalShow(true);
                                setMemberUID(element.iSpotUUID);
                              }}
                            >
                              <i className="fa-solid fa-arrows-rotate fs-5"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {secondColumnTitle}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>

                <th className="min-w-100px notVisibleInMobile">
                  {" "}
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eEnrol_Type"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirteenthColumnTitle}
                    {sortConfig.key !== "eEnrol_Type" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="payableFee"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "payableFee" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "payableFee" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "payableFee" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dueAmt"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "dueAmt" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dueAmt" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dueAmt" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="batchName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "batchName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtJoin"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {twelthColumnTitle}
                    {sortConfig.key !== "dtJoin" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtStartingdate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "dtStartingdate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtExpirydate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "dtExpirydate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>

                <th className="min-w-100px notVisibleInMobile">
                  {eleventhColumnTitle}
                </th>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin:: pagination  */}
        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  )
}

export default ExpireModalData