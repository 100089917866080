import React, { useState, useEffect } from 'react'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import { GetAdminSettings, validateCoupon } from '../../redux/AuthCRUD'
import { toast } from 'react-toastify'
import { createRazorpayOrder, loadScript } from './paymentUtils/razorpayUtils'
import axios from 'axios'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useHistory } from 'react-router-dom'
import { CountryCode } from '../../../../data/CountryCode'
const couponSchema = Yup.object().shape({
  couponcode: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required(''),
})

const CheckoutSchema = Yup.object().shape({
  billing_address: Yup.string().test('only-spaces', 'Please enter a valid address', (value: any) => {
    return !/^[\s]*$/.test(value); // Check if input consists only of spaces
  }).required('billing_address is required'),
  billing_email: Yup.string().email('Wrong user_email format').required('user_email is required'),
  billing_country: Yup.string().required('billing_country is required'),
  billing_state: Yup.string().required('billing_state is require'),
  billing_zipcode: Yup.string().required('billing_zipcode is required'),
})
// Add 'Razorpay' property to Window interface
interface CustomWindow extends Window {
  Razorpay: any
}

declare let window: CustomWindow
const Checkout = (props: any) => {
  const history = useHistory()
  const { planData, userData, userId } = props.location.state
  // console.log(planData, userData, userId)

  // const { planData, values } = props.location.state;
  const [finalPrice, setFinalPrice] = useState(
    planData.price ? planData.price : planData.PlanAmount
  )
  const [gst, setGST] = useState(false);
  const [gstRate, setGstRate] = useState(0.18);
  const [error, seterror] = useState<string>('')
  const [discount, setDiscount] = useState(0)
  const displayRolename = (roleid: any) => {
    return roleid === '2' ? 'Fitness Center' : 'Channel Partner'
  }

  // const getSettings = async () => {
  //   try {
  //     const response = await GetAdminSettings();
  //     if (response.status === 200) {
  //       const gstOnOff = response.data.data[2].superadminValue === 'YES';
  //       console.log("colled", gstOnOff);
  //       setGST(gstOnOff);
  //       let FinalPrice = 0;

  //       if (gstOnOff) {
  //         FinalPrice = finalPrice + finalPrice * gstRate;
  //       }
  //       formik.setFieldValue('plan_price',FinalPrice)
  //       setFinalPrice(FinalPrice);

  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   getSettings();

  // }, [])
  console.log(planData.iplanID)
  const couponFormik = useFormik({
    initialValues: {
      couponcode: '',
      plan_price: planData.price ? planData.price : planData.PlanAmount,
    },
    validationSchema: couponSchema,
    onSubmit: async (values) => {
      const response = await validateCoupon({...values,plan_id: planData.iplanID});
      // console.log(response)
      console.log(values)
      if (response.status === 200) {
        toast.success(response.data.message)
        setFinalPrice(response.data.data.finalPrice);
        formik.setFieldValue('plan_price', response.data.data.finalPrice);
        setDiscount(response.data.data.discount_amount);
      } else {
        toast.error("Invalid coupon code")
      }
    },
  })
  const formik = useFormik({
    initialValues: {
      plan_price: finalPrice,
      billing_address: '',
      billing_email: userData.user_email,
      billing_mobile: userData.user_mobile,
      billing_country: userData.user_country_code,
      billing_zipcode: '',
      billing_state: '',
      user_id: userId,
    },
    
    validationSchema: CheckoutSchema,
    enableReinitialize:true,
    onSubmit: async (values) => {

      try {
        const res: any = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
          alert('Razorpay sdk fail to load')
          return
        }
        const orderDetails = await createRazorpayOrder(values)
        const options = {
          key: 'rzp_test_E9AqOGvQZQ751k', // Replace with your Razorpay API key
          amount: orderDetails.amount,
          currency: 'INR', // Replace with your desired currency code
          order_id: orderDetails.id,
          name: 'Automize Gym',
          description: 'Payment for Plan', // Customize the payment description
          handler: async function (response: any) {
            // Payment success callback (Optional)
            console.log('Payment successful:', response)
            const updateResponse = await axios
              .post(`${process.env.REACT_APP_API_URL}/v1/user/demo`, {...values,plan_id: planData.iplanID})
              .catch((error) => {
                return error.response
              })
            history.push('/admin/auth/login')
            console.log(updateResponse)
            if (updateResponse.data.success) {
              toast.success('Billing information and payment status updated successfully!')
            } else {
              console.log(updateResponse)
              toast.error('Failed to update billing information and payment status.')
            }
            // You can redirect the user or show a success message here
          },
          prefill: {
            name: userData.billing_email || 'Default Name',
            email: userData.user_email || 'default@email.com',
          },
          notes: {
            address: 'Automize Gym',
          },
          theme: {
            color: '#61dafb',
          },
        }
        const razorpayInstance = new window.Razorpay(options)
        razorpayInstance.open()
      } catch (error) {
        console.log(error)
        toast.error('Error processing the payment')
      }
    },
  })

  return (
    <>
      <div className='container shadow-sm bg-white' style={{ marginTop: '-95px', zIndex: 3 }}>
        <h1 className='text-center my-3 pb-5 pt-4'>Checkout Page</h1>
        <div className='row'>
          {/* left Section */}
          <div className='col-md-7 p-4'>
            <div className='plan-details'>
              <h1 className=''>Plan Details:</h1>
              <div className='row bg-light p-3 rounded'>
                <div className='col-md-9'>
                  <p className='fw-bold text-dark fs-2'>Name :  {planData.membershipTitle} </p>
                  <p className='fs-6'>Descriprion: {planData.description}</p>
                  <p className='fs-6'>Branches: {planData.noOfBrachAllow}</p>
                  <p className='fs-6'>Plan duration : {planData.days}Days</p>
                </div>
              </div>
            </div>
            <div>
              <h1 className='my-6'>User Details :</h1>
            </div>
           <div className="row">
           <div className='col-xl-6 my-3'>
              <label className='form-label fw-bolder text-dark fs-6'>User Name</label>
              <input
                placeholder='Email'
                type='text'
                autoComplete='off'
                disabled
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                )}
                value={userData?.user_first_name}
              />
             
            </div>
            <div className='col-xl-6 my-3'>
              <label className='form-label fw-bolder text-dark fs-6'>Email Address</label>
              <input
                placeholder='Email'
                type='text'
                autoComplete='off'
                disabled
                {...formik.getFieldProps('billing_email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                 
                )}
              />
              {formik.touched.billing_email && formik.errors.billing_email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='error'>{formik.errors.billing_email}</span>
                  </div>
                </div>
              )}
            </div>
           </div>
            <div className="row">
            <div className='col-xl-6 my-3'>
              <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>
              <input
                placeholder='Mobile Number'
                type='text'
                autoComplete='off'
                disabled
                {...formik.getFieldProps('billing_mobile')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                 
                )}
              />
             
            </div>
            <div className='col-xl-6 my-3'>
              <label className='form-label fw-bolder text-dark fs-6'>Role</label>
              <input
                placeholder='Mobile Number'
                type='text'
                autoComplete='off'
                disabled
               value={displayRolename(userData?.user_role_id)}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                 
                )}
              />
            
            </div>
            </div>

            <h1 className='my-6'>Billing Details :</h1>
            <div className='col-xl-12 my-3'>
              <label className='form-label fw-bolder text-dark fs-6'>Enter Your address</label>
              <input
                placeholder='Your Address address'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('billing_address')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.billing_address && formik.errors.billing_address,
                  },
                  {
                    'is-valid': formik.touched.billing_address && !formik.errors.billing_address,
                  }
                )}
              />
              {formik.touched.billing_address && formik.errors.billing_address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='error'>{formik.errors.billing_address}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xl-12'>
              <label className='form-label fw-bolder text-dark fs-6'>Enter Zip-Code</label>
              <input
                placeholder='Enter Zip-Code'
                type='number'
                autoComplete='off'
                {...formik.getFieldProps('billing_zipcode')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.billing_zipcode && formik.errors.billing_zipcode,
                  },
                  {
                    'is-valid': formik.touched.billing_zipcode && !formik.errors.billing_zipcode,
                  }
                )}
              />
              {formik.touched.billing_zipcode && formik.errors.billing_zipcode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='error'>{formik.errors.billing_zipcode}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row my-3'>
              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Enter State</label>
                <input
                  placeholder='Enter State'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('billing_state')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.billing_state && formik.errors.billing_state,
                    },
                    {
                      'is-valid': formik.touched.billing_state && !formik.errors.billing_state,
                    }
                  )}
                />
                {formik.touched.billing_state && formik.errors.billing_state && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='error'>{formik.errors.billing_state}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Your Select Country
                </label>
                <div className=''>
                  <select
                    name='billing_country'
                    className='form-control form-control-lg form-control-solid'
                    onChange={formik.handleChange('billing_country')}
                    onBlur={formik.handleBlur('billing_country')}
                    value={formik.values.billing_country}
                    disabled
                  >
                    <option value=''>Select Country</option>
                    {CountryCode?.map((element: any, index) => {
                      return <option value={element.code} key={index}>{element.country}</option>
                    })}
                  </select>
                  {formik.touched.billing_country && formik.errors.billing_country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='error'>{formik.errors.billing_country}</span>
                      </div>
                    </div>
                  )}

                  <span className='absolute right-4 top-1/2 z-30 -translate-y-1/2'></span>
                </div>
              </div>
            </div>
            <div className='mt-2'>
              {/* Apply coupon code here  */}
              <div className='py-4'>
                <form onSubmit={couponFormik.handleSubmit}>
                  <div className='row my-4'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      Enter Your Coupone Code
                    </label>
                    <div className='col-7'>
                      <input
                        type='text'
                        name='couponcode'
                        className='form-control form-control-lg form-control-solid'
                        onChange={couponFormik.handleChange('couponcode')}
                        onBlur={couponFormik.handleBlur('couponcode')}
                        value={couponFormik.values.couponcode}
                      ></input>
                      {couponFormik.touched.couponcode && couponFormik.errors.couponcode && (
                        <div className='fv-plugins-message-container'>
                          <span className='error'>{couponFormik.errors.couponcode}</span>
                        </div>
                      )}
                      {error && (
                        <>
                          <div className='error'>{error}</div>
                        </>
                      )}
                    </div>
                    <div className='col-4'>
                      <button type='submit' className='btn btn-primary '>
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
          {/* Right Section */}
          <div className='col-md-5 p-4 position-relative '>
            <div className='d-flex flex-column h-100 '>
              {/* First Div: Half Height */}
              {/* <div className='bg-light p-4 mb-3 text-center rounded' style={{ height: '40%' }}>
                <div className=' bg-white d-inline-block p-3 shadow-sm'>

                  <div className='symbol symbol-100px rounded-circle '>
                    <div className='symbol-label fs-1 fw-semibold text-danger'>
                      {userData && userData.user_first_name
                        ? userData.user_first_name.substring(0, 1).toUpperCase()
                        : 'NOO'}
                    </div>
                  </div>

                </div>
                <h1 className='mt-3'>User Details :</h1>

                <p className='mb-1'>
                  <strong>Name:</strong> {userData?.user_first_name}
                </p>
                <p className='mb-1'>
                  <strong>Email:</strong> {userData?.user_email}
                </p>
                <p className='mb-0'>
                  <strong>role:</strong> {displayRolename(userData?.user_role_id)}
                </p>
                <p className='mb-1'>
                  <strong>Country Code:</strong> {userData?.user_country_code}
                </p>
              </div> */}

              {/* Third Div: 50px Height (Positioned Absolutely) */}


              {/* Second Div: Half Height */}
              <div className='p-4  bg-light rounded' >
                {/* final amount price  */}
                <div className='plan_price_details'>
                  <div className='p-4'>
                    <h1 className='py-4'>Plan Price Details:</h1>
                    <div className='d-flex justify-content-between align-items-center mb-3 pt-2 pb-2 '>
                      <h4 className='mb-0'>Plan Price:</h4>
                      <span>
                        {planData.price ? planData.price : planData.PlanAmount}
                      </span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3 pt-2 pb-2'>
                      <h4 className='mb-0'>IGST:</h4>
                      <span>0</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3 pt-2 pb-2'>
                      <h4 className='mb-0'>CGST:</h4>
                      <span>0</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3 pt-2 pb-2'>
                      <h4 className='mb-0'>Discount :</h4>
                      <span>{discount}</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center pt-2 pb-2 border-top'>
                      <h4 className='mb-0'>Final Price:</h4>
                      <span>{finalPrice}</span>
                    </div>
                  </div>
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <button
                        type='submit'
                        className='form-control form-control-lg  btn-primary text-light'
                      >
                        Make Payment
                      </button>
                    </form>
                  </div>
                </div>

                {/* <div className='ms-5'>
                 <h2 className='mt-10'>Module Information</h2>
                 <ul className='fs-4'>
                    {
                      planData.modules.map((element:any)=>{
                          return <li className='my-2'>{element.moduleName}</li>
                      })
                    }
                 </ul>
                 </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout
