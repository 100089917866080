import { Modal, Button } from 'react-bootstrap'
import EnquiryModalData from '../modalData/EnquiryModalData';
import ExpireModalData from '../modalData/ExpireModalData';
import FollowUpModalData from '../modalData/FollowUpModalData';
import DueModalAmount from '../modalData/DueModalAmount';
import InvoiceModalData from '../modalData/InvoiceModalData';
import TodayBirthdayModal from '../modalData/TodayBirthdayModal';
import TodayMemberBirthdayModal from '../modalData/TodayMemberBirthdayModal';
import TotalExpenseModal from '../modalData/TotalExpenseModal';
import TodayExpenseModal from '../modalData/TodayExpenseModal';

const EnquiryModal = ({ showModal, setShowModal, selectedDate, cardTitle }: any) => {
  // console.log(cardTitle);

  const handleClose = () => setShowModal(false);
  let modalContent;

  switch (cardTitle) {
    case "Enquiry Details":
      modalContent = <EnquiryModalData selectedDate={selectedDate} />;
      break;
    case "Expire Package":
      modalContent = <ExpireModalData selectedDate={selectedDate} />;
      break;
    case "FollowUps":
      modalContent = <FollowUpModalData selectedDate={selectedDate} />;
      break;
    case "Remaing Balance":
      modalContent = <DueModalAmount selectedDate={selectedDate} />;
      break;
    case "Invoice":
      modalContent = <InvoiceModalData selectedDate={selectedDate} />;
      break;
    case "Today Employee Birthday":
      modalContent = <TodayBirthdayModal selectedDate={selectedDate} />;
      break;
    case "Today Member Birthday":
      modalContent = <TodayMemberBirthdayModal selectedDate={selectedDate} />;
      break;
    case "Total Expense":
      modalContent = <TotalExpenseModal selectedDate={selectedDate} />;
      break;
      case "Today Expense":
        modalContent = <TodayExpenseModal selectedDate={selectedDate} />;
        break;
    default:
      modalContent = null;
  }
  return (
    <Modal show={showModal} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{cardTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalContent}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )

}

export default EnquiryModal