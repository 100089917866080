import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import permission from "../../../modules/Genric componant/permissions/permission";
import * as Expenses from "../../../modules/expenses/modules/expense/Redux/ExpenseRedux";
import { detailofUpdateExpense, GetExpenses } from "../../../modules/expenses/modules/expense/Redux/ExpenseCRUD";
import Pagination from "../../../modules/Genric componant/Pagination";
import { KTSVG } from "../../../../_metronic/helpers";
import ExpenseModal from "../../../modules/expenses/modules/expense/component/modal/ExpenseModal";

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const TodayExpenseModal = (selectedDate: any) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [sortConfig, setSortConfig] = useState<any>({
        key: "iExpenseID", // Default sorting column
        direction: "desc", // Default sorting direction
    });
    const Expense = useSelector((state: any) => state?.Expenses?.Expenses?.data);

    let selectedBranch: any = localStorage.getItem("ActiveBranch");
    const ActiveBranch = JSON.parse(selectedBranch);
    const ActiveBranchID = ActiveBranch.iBranchID;
    const ActiveBranchUUID = ActiveBranch.iBranchUUID;

    let ActiveUser: any = localStorage.getItem("activeUserType");
    const UserType = JSON.parse(ActiveUser);
    const Access = permission("expense");
    let add = 0,
        edit = 0,
        del = 0,
        view = 0;
    Access?.map((item: any) => {
        // console.log(item, "itemsss");
        if (item === "edit") {
            edit = 1;
        } else if (item === "delete") {
            del = 1;
        } else if (item === "add") {
            add = 1;
        } else {
            view = 1;
        }
    });

    const [UpdateUser, setUpdateUser] = useState<any>({
        expenseCatUUID: "",
        branchId: "",
        name: "",
        Status: "",
    });

    const [isUpdate, setisUpdate] = useState(false);


    //page state
    const startDate = selectedDate.selectedDate.startDate;
    const endDate = selectedDate.selectedDate.endDate;
    const [pageLimit, setPageLimit] = useState(pageDefaultValue);
    let initialState = {
        status: "",
        expenseCatID: "",
        page_limit: pageLimit,
        page_no: 1,
        search: "",
        branchID: ActiveBranch.iBranchID,
        start_date: startDate,
        end_date: endDate,
        order_key: "iExpenseID",
        order_value: "desc",
    };

    const [show, setShow] = useState<any>(false);
    const [activeCatagory, setActiveCatagory] = useState<any>();
    const handleShow = () => setShow(true);


    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [filterValues, setFilterValues] = useState(initialState);
    const [checkedRows, setCheckedRows] = useState<any>([]);

    const [filteredData, setFilteredData] = useState<any>([]);
    const [totalexp, setTotalexp] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [TBLData, setTBLData] = useState<any>([]);

    const ExpenseCatRowTitle = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.TITLE",
    })}`;
    // const ExpenseIdRowTitle = `${intl.formatMessage({
    //   id: "MODULE.MEMBERS.MEMBERID",
    // })}`;
    // const ExpenseCatRowStatus = `${intl.formatMessage({ id: "COMMON.STATUS" })}`;
    const ExpensepaidToRowAction = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.PAIDTO",
    })}`;
    const ExpenseAmountRowAction = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.AMOUNT",
    })}`;
    // const ExpenseExpenseRowAction = `${intl.formatMessage({
    //   id: "MODULE.EXPENSECATEGORY.DATE",
    // })}`;
    const ExpenseBillRowAction = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.BILLNO",
    })}`;
    const ExpenseRemarkRowAction = `${intl.formatMessage({
        id: "COMMON_REMARK",
    })}`;
    const ExpensepaymentmodeRowAction = `${intl.formatMessage({
        id: "MODULE.RECIEPT.PAYMENTMODE",
    })}`;
    const ExpenseDateRowAction = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.DATE",
    })}`;
    const ExpenseCatRowAction = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;
    const ExpenseCatRowChequeandDate = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.CHEQUE",
    })}`;
    const ExpenseCatRowBank = `${intl.formatMessage({
        id: "MODULE.EXPENSECATEGORY.BANKNAME",
    })}`;

    const handleSort = (Event: any) => {
        const key = Event.currentTarget.getAttribute("data-key");
        const value =
            sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
        setSortConfig({ key: key, direction: value });
    };

    const getExpenseCategory = useCallback(() => {
        if (UserType === "admin" || view !== 0) {
            setIsLoading(true);
            GetExpenses({
                ...filterValues,
                page_no: page,
                page_limit: pageLimit,
                order_key: sortConfig.key,
                order_value: sortConfig.direction,
            }).then((data: any) => {
                // console.log(data, "data");
                if (data?.status !== 200) {
                    setFilteredData([]);
                    setIsLoading(false);
                } else {
                    dispatch(Expenses.actions.GetExpenses(data));
                    setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
                    setIsLoading(false);
                }
            });
        }
    }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

    useEffect(() => {
        getExpenseCategory();
    }, [getExpenseCategory]);

    useEffect(() => {
        setFilteredData(Expense?.data);
        setTotalexp(Expense?.totalExpense);
        handleExports();
    }, [Expense?.data]);

    const getPageLimit = (values: any) => {
        setPageLimit(Number(values));
        setPage(1);
    };
    const getDetailsForUpdate = async (branchID: any, memberuid: any) => {
        const response = await detailofUpdateExpense(branchID, memberuid);
        setUpdateUser(response?.data?.data);
        // console.log(response, "detailsof");
    };

    const handleExports = () => {
        const tableRows = Expense?.data?.map((element: any, index: number) => {
            return {
                "#": index + 1,
                [ExpensepaidToRowAction]: element.catName,
                [ExpenseAmountRowAction]: element.paid_to,
                [ExpenseDateRowAction]: element.amount,
                [ExpensepaymentmodeRowAction]: element.expenseDate,
                [ExpenseCatRowChequeandDate]: element?.mode_of_payment,
                [ExpenseCatRowBank]: element.cheque_no + " " + element.cheque_date,
                [ExpenseBillRowAction]: element?.bank_name,
                [ExpenseRemarkRowAction]: element?.bill_no,
                [ExpenseCatRowTitle]: element.remarks,
            };
        });

        setTBLData(tableRows);
    };
    return (
        <>
            <div className='d-flex align-items-center'>
                <span
                    className="min-w-40px mb-3 d-flex  align-items-center"
                    style={{ color: "#7e8299", fontSize: "14px" }}
                >
                    {intl.formatMessage({ id: "COMMON.SHOW" })}
                </span>
                <div>
                    <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                    >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                            <option key={i} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <h1 className="mx-2 fs-4 ">
                    {intl.formatMessage({
                        id: "MODULE.EXPENSECATEGORY.TOTALEXP",
                    })}
                    {totalexp}
                </h1>
            </div>
            <ExpenseModal
                show={show}
                setShow={setShow}
                UpdateUser={UpdateUser}
                setUpdateUser={setUpdateUser}
                isUpdate={isUpdate}
                setisUpdate={setisUpdate}
                activeCatagory={activeCatagory}
                getExpenseCategory={getExpenseCategory}
            />

            <div className="card-body py-3">
                {/* {isLoading && <Loading />} */}
                {/* begin::Table container */}
                <div className="table-responsive">
                    {/* begin::Table */}
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        {/* begin::Table head */}
                        <thead>
                            <tr className="fw-bolder text-muted text-capitalize fs-4">
                                {/* <th className=" mobilewidth">
                                      <div className="mobileViewalign w-100">
                                          <div className=" selectAll ">
                                              {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                                          </div>
                                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                                              <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value="1"
                                                  data-kt-check="true"
                                                  data-kt-check-target=".widget-9-check"
                                                  checked={isAllRowsChecked}
                                                  onChange={() => handleSelectAllCheckboxChange()}
                                              />
                                          </div>
                                      </div>
                                  </th> */}
                                {/* <th className="min-w-100px">{ExpenseIdRowTitle}</th> */}
                                <th className="min-w-100px notVisibleInMobile ">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="catName"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseCatRowTitle}
                                        {sortConfig.key !== "catName" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "catName" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "catName" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>

                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="paid_to"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        <div>{ExpensepaidToRowAction}</div>
                                        {sortConfig.key !== "paid_to" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "paid_to" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "paid_to" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>

                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="amount"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        <div> {ExpenseAmountRowAction}</div>
                                        {sortConfig.key !== "amount" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "amount" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "amount" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="expenseDate"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseDateRowAction}
                                        {sortConfig.key !== "expenseDate" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "expenseDate" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "expenseDate" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="mode_of_payment"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpensepaymentmodeRowAction}
                                        {sortConfig.key !== "mode_of_payment" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "mode_of_payment" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "mode_of_payment" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="cheque_no"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseCatRowChequeandDate}
                                        {sortConfig.key !== "cheque_no" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "cheque_no" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "cheque_no" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="bank_name"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseCatRowBank}
                                        {sortConfig.key !== "bank_name" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "bank_name" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "bank_name" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="bill_no"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseBillRowAction}
                                        {sortConfig.key !== "bill_no" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "bill_no" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "bill_no" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="remarks"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseRemarkRowAction}
                                        {sortConfig.key !== "remarks" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "remarks" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "remarks" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>

                                {/* <th className="min-w-100px notVisibleInMobile">
                        {ExpenseCatRowStatus}
                      </th> */}
                                <th className="min-w-100px notVisibleInMobile">
                                    {ExpenseCatRowAction}
                                </th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {filteredData && filteredData.length === 0 ? (
                                <tr>
                                    <td colSpan={10} className="text-center">
                                        {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                                    </td>
                                </tr>
                            ) : UserType === "employee" && view === 0 ? (
                                <tr>
                                    <td colSpan={10} className="text-center text-danger fs-4">
                                        {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                                    </td>
                                </tr>
                            ) : (
                                filteredData?.map((element: any, index: number) => {
                                    const isChecked = checkedRows.includes(
                                        element.iExpenseUUID
                                    );
                                    return (
                                        <>
                                            <tr key={index} className="fs-5">

                                                <td data-label={ExpenseCatRowTitle}>
                                                    {element.catName}
                                                </td>
                                                <td data-label={ExpensepaidToRowAction}>
                                                    {element.paid_to}
                                                </td>
                                                <td data-label={ExpenseAmountRowAction}>
                                                    {element.amount}
                                                </td>
                                                <td data-label={ExpenseDateRowAction}>
                                                    {element.expenseDate.split("T")[0]}
                                                </td>
                                                <td data-label={ExpensepaymentmodeRowAction}>
                                                    {element.mode_of_payment}
                                                </td>
                                                <td data-label={ExpenseCatRowChequeandDate}>
                                                    {`${element?.cheque_no ? element?.cheque_no : ""} ${element?.cheque_date ? element?.cheque_date : ""
                                                        }`}
                                                </td>
                                                <td data-label={ExpenseCatRowBank}>
                                                    {element.bank_name}
                                                </td>

                                                <td data-label={ExpenseBillRowAction}>
                                                    {element.bill_no}
                                                </td>
                                                <td data-label={ExpenseRemarkRowAction}>
                                                    {element.remarks}
                                                </td>

                                                {/* <td data-label={ExpenseCatRowStatus}>
                                {StatusBadge(element.eStatus)}
                              </td> */}
                                                <td data-label={ExpenseCatRowAction}>
                                                    <div className="d-flex flex-shrink-0 floatRight">
                                                        <a
                                                            href="#"
                                                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${edit === 0 &&
                                                                UserType === "employee" &&
                                                                "d-none"
                                                                }`}
                                                            onClick={() => {
                                                                getDetailsForUpdate(
                                                                    ActiveBranchID,
                                                                    element.iExpenseUUID
                                                                );
                                                                setisUpdate(true);
                                                                setShow(true);
                                                            }}
                                                        >
                                                            <KTSVG
                                                                path="/media/icons/duotune/art/art005.svg"
                                                                className="svg-icon-3"
                                                            />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            )}
                            <tr className="fw-bolder text-muted text-capitalize fs-4">
                                {/* <th className=" mobilewidth">
                                      <div className="mobileViewalign w-100">
                                          <div className=" selectAll ">
                                              {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                                          </div>
                                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                                              <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value="1"
                                                  data-kt-check="true"
                                                  data-kt-check-target=".widget-9-check"
                                                  checked={isAllRowsChecked}
                                                  onChange={() => handleSelectAllCheckboxChange()}
                                              />
                                          </div>
                                      </div>
                                  </th> */}
                                {/* <th className="min-w-100px">{ExpenseIdRowTitle}</th> */}
                                <th className="min-w-100px notVisibleInMobile ">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="catName"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseCatRowTitle}
                                        {sortConfig.key !== "catName" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "catName" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "catName" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>

                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="paid_to"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        <div>{ExpensepaidToRowAction}</div>
                                        {sortConfig.key !== "paid_to" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "paid_to" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "paid_to" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>

                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="amount"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        <div> {ExpenseAmountRowAction}</div>
                                        {sortConfig.key !== "amount" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "amount" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "amount" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="expenseDate"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseDateRowAction}
                                        {sortConfig.key !== "expenseDate" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "expenseDate" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "expenseDate" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="mode_of_payment"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpensepaymentmodeRowAction}
                                        {sortConfig.key !== "mode_of_payment" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "mode_of_payment" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "mode_of_payment" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="cheque_no"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseCatRowChequeandDate}
                                        {sortConfig.key !== "cheque_no" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "cheque_no" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "cheque_no" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="bank_name"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseCatRowBank}
                                        {sortConfig.key !== "bank_name" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "bank_name" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "bank_name" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="bill_no"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseBillRowAction}
                                        {sortConfig.key !== "bill_no" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "bill_no" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "bill_no" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>
                                <th className="min-w-100px notVisibleInMobile">
                                    <div
                                        className="d-flex justify-content-between cursor-pointer"
                                        data-key="remarks"
                                        onClick={(e) => {
                                            handleSort(e);
                                        }}
                                    >
                                        {ExpenseRemarkRowAction}
                                        {sortConfig.key !== "remarks" ? (
                                            <div>
                                                <i className="bi bi-arrow-down-up"></i>
                                            </div>
                                        ) : (
                                            <div>
                                                <i
                                                    className={`bi bi-arrow-up  ${sortConfig.key == "remarks" &&
                                                        sortConfig.direction == "asc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                                <i
                                                    className={`bi bi-arrow-down  ${sortConfig.key == "remarks" &&
                                                        sortConfig.direction == "desc"
                                                        ? "d-block text-primary fw-bold fs-5"
                                                        : "d-none"
                                                        }`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </th>

                                {/* <th className="min-w-100px notVisibleInMobile">
                        {ExpenseCatRowStatus}
                      </th> */}
                                <th className="min-w-100px notVisibleInMobile">
                                    {ExpenseCatRowAction}
                                </th>
                            </tr>
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
                {/* begin:: pagination  */}
                {filteredData && filteredData.length > 0 && (
                    <Pagination page={page} totalPages={totalPages} setPage={setPage} />
                )}
                {/* end:: pagination */}
            </div>


        </>
    )
}

export default TodayExpenseModal