import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_ENQUIRY = `${API_URL}/v1/enquiry/list`;
export const GET_ALL_FOLLOWUP = `${API_URL}/v1/followup/list`;
export const CREATE_ENQUIRY = `${API_URL}/v1/enquiry/add`;
export const UPDATE_ENQUIRY = `${API_URL}/v1/enquiry/update`;
export const UPDATE_FOLLOWUP = `${API_URL}/v1/followup/update`;
export const UPDATE_ENQUIRY_STATUS = `${API_URL}/v1/enquiry/update_status`;
export const DELETE_ENQUIRY = `${API_URL}/v1/enquiry/delete`;
export const DELETE_FOLLOWUP = `${API_URL}/v1/followup/delete`;
export const DETAILS_ENQUIRY = `${API_URL}/v1/enquiry/details/`;
export const DETAILS_FOLLOWUP = `${API_URL}/v1/followup/details/`;
export const ACTIVE_PACKAGE = `${API_URL}/v1/package/listbyBranch/`;
export const ACTIVE_CATAGORY = `${API_URL}/v1/leadcategory/listbyBranch/`;
export const ACTIVE_ENQUIRY_TYPE = `${API_URL}/v1/enquirytype/listbyBranch/`;
export const ACTIVE_ENQUIRY_STATUS = `${API_URL}/v1/common/enquiryStatusList/`;
export const ACTIVE_OCCUPATION = `${API_URL}/v1/occupation/listbyBranch/`;
export const ACTIVE_EMPLOYEE = `${API_URL}/v1/employee/listbyBranch/`;
export const DETAILS_PACKAGE = `${API_URL}/v1/package/details/`;
export const ADD_FOLLOWUP = `${API_URL}/v1/followup/add`;
// Create a package
export function CreateEnquiry(body: any) {
  return axios.post(CREATE_ENQUIRY, body).catch((error) => {
    return error.response;
  });
}

// Add follow up
export function AddFollowUp(body: any) {
  return axios.post(ADD_FOLLOWUP, body).catch((error) => {
    return error.response;
  });
}

// Get all Enquiry
export function GetEnquiry(data: any) {
  return axios.post(GET_ALL_ENQUIRY, data).catch((error) => {
    return error.response;
  });
}

// Get all followUp
export function GetFollowUp(data: any) {
  return axios.post(GET_ALL_FOLLOWUP, data).catch((error) => {
    return error.response;
  });
}

// Get active package details
export function GetActivePackage(branchID: any) {
  return axios.get(`${ACTIVE_PACKAGE}${branchID}`).catch((error) => {
    return error.response;
  });
}
export function GetEnquiryCatagory(branchID: any) {
  return axios.get(`${ACTIVE_CATAGORY}${branchID}`).catch((error) => {
    return error.response;
  });
}
export function GetEnquiryType(branchID: any) {
  return axios.get(`${ACTIVE_ENQUIRY_TYPE}${branchID}`).catch((error) => {
    return error.response;
  });
}
export function GetEnquiryStatus(branchID: any) {
  return axios.get(`${ACTIVE_ENQUIRY_STATUS}${branchID}`).catch((error) => {
    return error.response;
  });
}
export function GetOccupation(branchID: any) {
  return axios.get(`${ACTIVE_OCCUPATION}${branchID}`).catch((error) => {
    return error.response;
  });
}
export function getDeatilaPackages(branchID: any, data: any) {
  return axios.get(`${DETAILS_PACKAGE}${branchID}/${data}`).catch((error) => {
    return error.response;
  });
}
export function GetEmployees(branchID: any) {
  return axios.get(`${ACTIVE_EMPLOYEE}${branchID}`).catch((error) => {
    return error.response;
  });
}

// Get enquiry Details
export function GetEnquiryDetails(branchID: any, EnquiryUUID: any) {
  return axios
    .get(`${DETAILS_ENQUIRY}${branchID}/${EnquiryUUID}`)
    .catch((error) => {
      return error.response;
    });
}

// Get Followup Details
export function GetFollowUpDetails(branchID: any, followUpUUID: any) {
  return axios
    .get(`${DETAILS_FOLLOWUP}${branchID}/${followUpUUID}`)
    .catch((error) => {
      return error.response;
    });
}

// Update a enquiry
export function UpdateEnquiry(body: any) {
  return axios.put(UPDATE_ENQUIRY, body).catch((error) => {
    return error.response;
  });
}

// Update follow Up
export function UpdateFollowUp(body: any) {
  return axios.put(UPDATE_FOLLOWUP, body).catch((error) => {
    return error.response;
  });
}

// Update enquiry status
export function UpdateEnquiryStatus(body: any) {
  return axios.patch(UPDATE_ENQUIRY_STATUS, body).catch((error) => {
    return error.response;
  });
}
// Delete enquiry
export function DeleteEnquiry(body: any) {
  return axios.patch(DELETE_ENQUIRY, body).catch((error) => {
    return error.response;
  });
}

// Delete followUp
export function DeleteFollowUp(body: any) {
  return axios.patch(DELETE_FOLLOWUP, body).catch((error) => {
    return error.response;
  });
}
