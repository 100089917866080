// export const hasPermission = (
//   roles: any,
//   role_id: any,
//   // activeBranch:any,
  
//   // moduleName: any,
//   // requiredPermission: any,
//   path: any,
//   permission: any,
//   activeBranch: any
// ) => {
  
//   if (role_id === 1) {
//     return true
//   }

//   // check bypass routes
//   if (permission?.length === 0) {
//     return true
//   }

//   console.log('path::::', path, 'activeBranch:::', activeBranch)
//   if(!activeBranch) {
//     console.log('activeBranch is false............................')
//     return false
//   } 

//   //module name
//   let moduleName = (permission) ? permission[0]?.moduleName : null;
//   const module = roles?.find((role: any) => role?.moduleName === moduleName)

//   // Grant access if module is not found or permissions are null
//   if (!module || module.permissions === null) {
//     console.log('module.permissions is false............................')
//     return false
//   }
  
//   //module name
//   let requiredPermission = (permission) ? permission[0]?.requiredPermission : null;
//   let result = module.permissions?.includes(requiredPermission)

//   console.log('result............................', result)
//   return result;
// }

export const hasPermission = (
  roles: any,
  role_id: any,
  moduleName: any,
  requiredPermission: any
) => {
  if (role_id === 1) {
    return true
  }
  //module name
  const module = roles?.find((role: any) => role?.moduleName === moduleName)

  // Grant access if module is not found or permissions are null
  if (!module || module.permissions === null) {
    return false
  }
  return module.permissions.includes(requiredPermission)
}
