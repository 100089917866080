/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Checkout from "./payment/component/Checkout";
import { MemberLogin } from "./components/MemberLogin";
import { OtpLogin } from "./components/OtpLogin";
import { OtpVerify } from "./components/OtpVerify";
import { ForgorMemberPassword } from "./components/planmodel/ForgotMemberPassword";
import { ResetPassword } from "./components/planmodel/ResetPassword";
import { ResetpasswordUser } from "./components/planmodel/ResetpasswordUser";
export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-secondary");
    return () => {
      document.body.classList.remove("bg-secondary");
    };
  }, []);

  return (
    <>
      <div
        className="d-flex bg-white flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        // style={{
        //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        // }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* begin::Logo */}
          <p className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logo.png")}
              className="h-55px"
              style={{ mixBlendMode: "darken", zIndex: 1 }}
            />
          </p>
          {/* end::Logo */}
          {/* begin::Wrapper */}

          <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/registration" component={Registration} />
            <Route path="/auth/memberlogin" component={MemberLogin} />
            <Route path="/auth/forgot-password" component={ForgotPassword} />
            <Route
              path="/auth/forgot-member-password"
              component={ForgorMemberPassword}
            />
            <Route
              path="/auth/reset-member-password"
              component={ResetPassword}
            />
            <Route
              path="/auth/reset-user-password"
              component={ResetpasswordUser}
            />
            {/* <Route path='/auth/checkout' component={CheckoutPage}/> */}
            <Route path="/auth/checkout" component={Checkout} />
            <Route path="/auth/checkoutpage" component={Checkout} />
            <Route path="/auth/otp-login" component={OtpLogin} />
            <Route path="/auth/otp-verify" component={OtpVerify} />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>

          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
        {/* end::Footer */}
      </div>
    </>
  );
}
