/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GetInvoiceDetails } from "./CRUD";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// import { findBY } from "../genric componant/findMethodBykey/findBY";
import moment from "moment";

export function InvoicePage() {
  const printRef = useRef<any>();
  const admin: any = localStorage.getItem("ActiveBranch");
  const adminSetting = JSON.parse(admin);
  // const DateFormat = findBY(adminSetting, "DefaultDateFormate");
  const DateFormat = adminSetting?.idateFormate;
  // const address =" findBY(adminSetting, "Address");"
  const address = adminSetting?.InvoiceNotes;
  // const invoiceNotes = "findBY(adminSetting, "InvoiceNotes");"
  const invoiceNotes = adminSetting?.InvoiceNotes;

  const params: any = useParams();
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [superAdminData, setSuperAdminData] = useState<any>({});
  const [planData, setPlanData] = useState<any>({});
  const [userData, setUserData] = useState<any>({});
  const [coupon, setCoupon] = useState<any>({});
  useEffect(() => {
    GetInvoiceDetails(params.id).then((response: any) => {
      // console.log(response, "dskdsalk");
      const data = response.data.data.USERPLAN; // Assuming the first element is invoice data
      const plan = response.data.data.PLAN; // Assuming the second element is plan data
      const user = response.data.data.USER;
      const coupon = response.data.data.COUPON;
      const superAdminData = response.data.data.superAdminSetting;
      setInvoiceData(data);
      setPlanData(plan);
      setUserData(user);
      setCoupon(coupon);
      setSuperAdminData(superAdminData);
    });
  }, [params.id]);
  const handlePrint = () => {
    const printContent: any = printRef.current.innerHTML;
    const printWindow: any = window.open("", "", "height=800,width=900");
    printWindow.document.write("<html><head>");
    printWindow.document.write(
      '<link href="https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha1/css/bootstrap.min.css" rel="stylesheet">'
    );
    printWindow.document.write("<title>Print</title><style>");
    // Add your custom styles here
    printWindow.document.write(`
     @media print {
     { html { height: 99%; } }
  body {
    font-family: Arial, sans-serif;
    font-size:12px;
    margin: 0;
    padding: 0;
    height: 99%;
  }

  .invoice-container {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .invoice-header {
    margin-bottom: 1rem;
  }

  .invoice-col {
    margin-bottom: 1rem;
  }

  .invoice-col img {
    max-height: 55px;
    margin: 0 auto;
    display: block;
  }

  .invoice-status {
    margin-bottom: 1rem;
  }

  .invoice-status .btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .text-end {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .border {
    border: 1px solid #ddd;
  }

  .border-2 {
    border-width: 2px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }

  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
  }

  .table th {
    background-color: #f2f2f2;
  }

  .total-row {
    font-weight: bold;
  }

  .panel {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
  }

  .panel-heading {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #ddd;
  }

  .panel-body {
    padding: 1.25rem;
  }

  .hidden-print {
    display: none;
  }

  .btn {
    display: inline-block;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
    color: #000;
  }

  .btn-default {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #000;
  }

  .btn-success {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
  }

  .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
  }

  .floatRight {
    float: right;
  }

  .fs-7 {
    font-size: 0.875rem;
  }

  .fs-8 {
    font-size: 0.75rem;
  }

  .text-dark {
    color: #000;
  }

  .lh-1 {
    line-height: 1;
  }
    .row {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
  }

  .col {
    flex: 1;
    padding: 15px;
    box-sizing: border-box;
  }

  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
.pad{
padding:20px;
}
.shifting-LR{
    margin:0px 5px 0 5px;
    }

  .no-gutters > .col,
  .no-gutters > .col > .row {
    margin-right: 0;
    margin-left: 0;
  }
    
     .margin-bottm{
     margin:10px 5px
     }
     
  
}

    `);
    printWindow.document.write("</style></head><body>");
    printWindow.document.write(printContent);
    printWindow.document.write("</body></html>");
    setTimeout(() => printWindow.print(), 1000);
  };
  return (
    <>
      <div className="container-fluid invoice-container " ref={printRef}>
        <div className="row invoice-header">
          <div className="invoice-col">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logo.png")}
              className="h-55px d-flex my-3 mx-auto"
              style={{ mixBlendMode: "darken", zIndex: 1 }}
            />
            <h3 className="mt-6">Invoice No. : {invoiceData.iuserPlanID}</h3>
          </div>
          <div className="invoice-col text-center ">
            <div className="invoice-status">
              <span
                className={`btn ${
                  invoiceData?.paymentStatus !== 0
                    ? "btn-success"
                    : "btn-danger"
                } `}
              >
                {invoiceData?.paymentStatus !== 1 ? "UnPaid" : "Paid"}
              </span>
            </div>
          </div>
        </div>
        <div className="row shifting-LR">
          <div className="col-md-6  text-start ">
            <strong>Invoiced To</strong>
            <address className="small-text">
              {userData?.vFirstName} {userData?.vLastName}
              <br />
              {userData?.vEmail}
              <br />
              {userData?.vCountryCode} {userData?.vMobile}
              <br />
              {userData?.vaddress}
              <br />
            </address>
          </div>
          <div className="col-md-6 invoice-col text-end ">
            <strong>Pay To</strong>
            <address className="small-text">
              {superAdminData?.companyName}
              <br />
              <AddressComponent address={superAdminData?.address} />
            </address>
          </div>
        </div>
        <div className="row margin-bottm">
          <div className="col-md-3 border border-2 d-flex align-items-center pad">
            <strong>Payment Method : </strong>

            <span className="small-text ps-1" data-role="paymethod-info">
              {invoiceData.paymentMode}
            </span>
            <br />
            <br />
          </div>
          <div className="col-md-3 border border-2 d-flex align-items-center pad">
            <strong>InvoiceDate :</strong>

            <span className="small-text ps-1">
              {moment(invoiceData.PlanStartDate).format(DateFormat)}
            </span>
            <br />
            <br />
          </div>
          <div className="col-md-3 border border-2 d-flex align-items-center pad">
            <strong>Amount :</strong>
            <span className="small-text ps-1">
              {invoiceData?.finalUserPlanAmount +
                " " +
                invoiceData?.currencySymbol}
            </span>
            <br />
            <br />
          </div>
          <div className="col-md-3 border border-2 d-flex align-items-center pad">
            <strong>ReferneceNo :</strong>

            <span className="small-text ps-1">
              {invoiceData?.transactionId}
            </span>
            <br />
            <br />
          </div>
        </div>
        <br />
        {invoiceData?.paymentMode === "online" && (
          <div className="row margin-bottm">
            <div className="col-md-3 border border-2 d-flex align-items-center pad">
              <strong>Transaction ID: </strong>

              <span className="small-text ps-1" data-role="paymethod-info">
                {invoiceData?.transactionId}
              </span>
              <br />
              <br />
            </div>
            <div className="col-md-4 border border-2 d-flex align-items-center pad">
              <strong>Transaction Date :</strong>

              <span className="small-text ps-1">
                {moment(invoiceData?.PlanStartDate).format(DateFormat)}
              </span>
              <br />
              <br />
            </div>
            <div className="col-md-3 border border-2 d-flex align-items-center pad">
              <strong>Gateway:</strong>

              <span className="small-text ps-1">
                {invoiceData?.paymentSource}
              </span>
              <br />
              <br />
            </div>
            <div className="col-md-2 border border-2 d-flex align-items-center pad">
              <strong>Amount :</strong>

              <span className="small-text ps-1">
                {invoiceData?.finalUserPlanAmount +
                  " " +
                  invoiceData?.currencySymbol}
              </span>
              <br />
              <br />
            </div>
          </div>
        )}
        <br />
        <h3 className="panel-title">
          <strong>Description</strong>
        </h3>

        <div className="table table-striped table-responsive ms-2 me-2">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>
                  <strong>S No.</strong>
                </th>
                <th>
                  <strong>Plan</strong>
                </th>
                <th>
                  <strong>Start Date</strong>
                </th>
                <th>
                  <strong>Expire Date</strong>
                </th>
                <th>
                  <strong>Amount {" " + invoiceData?.currencySymbol}</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{planData.membershipTitle}</td>
                <td>{moment(invoiceData?.PlanStartDate).format(DateFormat)}</td>
                <td>
                  {moment(invoiceData?.PlanExpireDate).format(DateFormat)}
                </td>
                <td className=" text-center">{planData?.price}</td>
              </tr>
              <tr>
                <td className=" text-end" colSpan={4}>
                  <strong>Sub Total</strong>
                </td>
                <td className=" text-center">{planData.price}</td>
              </tr>
              <tr>
                <td className=" text-end" colSpan={4}>
                  {invoiceData.CGST && invoiceData?.SGST ? (
                    <strong>Tax(CGST/IGST)</strong>
                  ) : (
                    <strong>Tax(GST)</strong>
                  )}
                </td>
                <td className=" text-center">
                  {invoiceData.CGST && invoiceData?.SGST
                    ? invoiceData?.SGST + invoiceData.CGST
                    : invoiceData.IGST}
                </td>
              </tr>
              <tr>
                <td className=" text-end" colSpan={4}>
                  <strong>Discount</strong>
                </td>
                <td className=" text-center">{invoiceData.finalDiscount}</td>
              </tr>
              <tr>
                <td className=" text-end" colSpan={4}>
                  <strong>Total</strong>
                </td>
                <td className=" text-center">
                  {invoiceData.finalUserPlanAmount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <p>Note:</p>
          <TextComponent address={superAdminData?.invoiceNote} />
          {/*     {console.log(invoiceNotes, "invoiceNotes")} */}
        </div>

        <div className="pull-right  hidden-print p-3 w-100">
          <div className="d-flex justify-content-end">
            <a
              onClick={() => handlePrint()}
              className="btn btn-primary text-center"
            >
              <i className="fas fa-print"></i> Print
            </a>
          </div>
        </div>
      </div>
      <p className="text-center hidden-print">
        <Link to={"/membership/plan/"}>back to client area</Link>
      </p>
    </>
  );
}
const AddressComponent = ({ address, data }: any) => {
  const parser = new DOMParser();
  const decodedString: any = parser.parseFromString(address, "text/html").body
    .textContent;

  // Remove the <p> tags and return plain text
  const plainText = decodedString.replace(/<\/?p>/g, "");

  return (
    <div style={{ whiteSpace: "pre-line", width: data }} className="text-end">
      {plainText}
    </div>
  );
};
const TextComponent = ({ address }: any) => {
  // Create a temporary DOM element to decode the HTML entities
  const parser = new DOMParser();
  const decodedString =
    parser.parseFromString(address, "text/html").body.textContent || "";
  const plainText = decodedString.replace(/<\/?p>/g, "");
  const txt = plainText.split(".");

  return (
    <>
      {txt?.map((item: any) => {
        return <p className="fs-8">{item}</p>;
      })}
    </>
  );
};
