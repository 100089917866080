import axios from "axios";
import { error } from "console";

import { useLang } from "../../../../../../_metronic/i18n/Metronici18n";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_EMPLOYEES = `${API_URL}/v1/employee/list`;
export const GET_ROLL = `${API_URL}/v1/role/activelist`;
export const GET_Department = `${API_URL}/v1/department/listbyBranch`;
export const GET_designation = `${API_URL}/v1/designation/listbyBranch`;
export const CREATE_EMPLOYEE = `${API_URL}/v1/employee/add`;
export const UPDATE_EMPLOYEE = `${API_URL}/v1/employee/update`;
export const UPDATE_EMPLOYEE_STATUS = `${API_URL}/v1/employee/update_status`;
export const DELETE_EMPLOYEE = `${API_URL}/v1/employee/delete`;
export const MARITAL_STATUS = `${API_URL}/v1/common/martiallist`;
export const BLOOD_GROUP = `${API_URL}/v1/common/bloodgrouplist`;
export const GENDER_LIST = `${API_URL}/v1/common/genderlist`;
export const EMPLOYEE_DETAILS = `${API_URL}/v1/employee/details`;
export const GET_ALL_ACTIVE_EMPLOYEE = `${API_URL}/v1/employee/listbyBranch/`;

// Create a department
export function CreateEmployee(body: any) {
  return axios.post(CREATE_EMPLOYEE, body).catch((error) => {
    return error.response;
  });
}

// Get all departments
export function GetEmployees(data?: any) {
  return axios.post(GET_ALL_EMPLOYEES, data).catch((error) => {
    return error.response;
  });
}
// Get Employee Details
export function GetEmployeeDetails(branchID: any, employeeUUID: any) {
  console.log("branchID api calling", branchID);
  return axios
    .get(`${EMPLOYEE_DETAILS}/${branchID}/${employeeUUID}`)
    .catch((error) => {
      return error.response;
    });
}
// Get all martial status
export function GetMartialStatus(lang: any) {
  return axios.get(`${MARITAL_STATUS}/${lang}`).catch((error) => {
    return error.response;
  });
}
export function getActiveRoll() {
  return axios.get(GET_ROLL).catch((error) => {
    return error.response;
  });
}
export function getActiveDepartment(data: any) {
  return axios.get(`${GET_Department}/${data}`).catch((error) => {
    return error.response;
  });
}
export function getActiveDesignation(data: any, UUID: any) {
  return axios.get(`${GET_designation}/${data}/${UUID}`).catch((error) => {
    return error.response;
  });
}
// Get all blood group list
export function GetBloodGroup(lang: any) {
  return axios.get(`${BLOOD_GROUP}/${lang}`).catch((error) => {
    return error.response;
  });
}
// // Get all gender list
export function GetGenders(lang: any) {
  return axios.get(`${GENDER_LIST}/${lang}`).catch((error) => {
    return error.response;
  });
}

// Update a department
export function UpdateEmployee(body: any) {
  return axios.put(UPDATE_EMPLOYEE, body).catch((error) => {
    return error.response;
  });
}

// Update department status
export function UpdateEmployeeStatus(body: any) {
  return axios.patch(UPDATE_EMPLOYEE_STATUS, body).catch((error) => {
    return error.response;
  });
}

// Get all active employee list
export function GetActiveEmployees(branchUUID: string) {
  return axios.get(GET_ALL_ACTIVE_EMPLOYEE + branchUUID).catch((error) => {
    return error.response;
  });
}

// Delete department
export function DeleteEmployee(body: any) {
  return axios.patch(DELETE_EMPLOYEE, body).catch((error) => {
    return error.response;
  });
}
