export const setImagePath = (data: any) => ({
  type: "SET_IMAGE_PATH",
  payload: data,
});
const initialState = {
  data: "", // Default value
};

// Reducer to handle image path actions
export const imageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_IMAGE_PATH":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
