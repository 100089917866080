/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../setup";
import { useIntl } from "react-intl";
import { GetEnquiry } from "../../../../app/memberModules/Enquiry/redux/EnquiryCRUD";
// import { Languages } from "./Languages";
// import { KTSVG } from "../../../helpers";

const HeaderUserMenu: FC = () => {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  // console.log(user, "employeeAuth");
  // const [UpdateUser, setUpdateUser] = useState<any>();
  // const [isUpdate, setisUpdate] = useState<any>();
  let data2: any = localStorage?.getItem("activeUser");
  const ActiveUser = JSON.parse(data2);
  let data1: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(data1);
  let selectedBranch: any = localStorage?.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);

  // console.log("activebranch", ActiveBranch);
  const ActiveBranchID = ActiveBranch?.iBranchID;
  // const staticCompanyUUID = "8a1dd83a-21b5-11ef-9aad-0a0b307bf5c2";
  const staticCompanyUUID = ActiveBranch?.iBranchCompanyUUID;
  // const staticSettingUUID = "b6827a48-013a-4906-9a40-9acb48225a14";
  const staticSettingUUID = ActiveBranch?.iBranchSettingUUID;
  // console.log(ActiveBranch, "acitvebranch");
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          {!ActiveUser?.image ? (
            <div className="symbol symbol-50px symbol-circle me-5">
              <div className="symbol-label fs-2 fw-semibold text-danger">
                {user?.name?.substring(0, 1).toUpperCase()}
              </div>
            </div>
          ) : (
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <div className="symbol-label">
                <img
                  src={`${ActiveUser?.imageurl}/${ActiveUser?.image}`}
                  width={50}
                  height={50}
                />
              </div>
            </div>
          )}

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user?.vFirstName} {user?.vLastName}
              {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                Pro
              </span> */}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user?.vEmail}
            </a>
          </div>
        </div>
      </div>
      <div className="menu-item px-5">
        <Link to={"/crafted/account/overview"} className="menu-link px-5">
          {intl.formatMessage({ id: "COMMON.PROFILE" })}
        </Link>
      </div>
      {userType !== "member" && userType !== "employee" && (
        <div className="menu-item px-5">
          <Link to={"/membership/plan/"} className="menu-link px-5">
            {intl.formatMessage({ id: "COMMON.MEMBERSHIP.HEADING" })}
          </Link>
        </div>
      )}
      {userType !== "member" && (
        <div className="menu-item px-5">
          <Link
            to={`/Company/details/${ActiveBranchID}/${staticCompanyUUID}`}
            className="menu-link px-5"
          >
            {intl.formatMessage({ id: "COMMON.COMPANY" })}
          </Link>
        </div>
      )}
      {userType === "member" && (
        <div className="menu-item px-5">
          <Link
            to={{
              pathname: `/usermember/membership/list/expired`,
              state: { packageType: "expired" },
            }}
            className="menu-link px-5"
          >
            {intl.formatMessage({ id: "MODULE.MEMBERS.MyEXPIREPACKAGES" })}
          </Link>
        </div>
      )}
      {userType === "member" && (
        <div className="menu-item px-5">
          <Link to={`/usermember/enquiry/list`} className="menu-link px-5">
            {intl.formatMessage({ id: "MEMBER.ENQUIRYLIST" })}
          </Link>
        </div>
      )}
      {userType !== "member" && (
        <div className="menu-item px-5">
          <Link
            to={`/setting/details/${staticSettingUUID}`}
            className="menu-link px-5"
          >
            {intl.formatMessage({ id: "COMMON.SETTING" })}
          </Link>
        </div>
      )}
      {userType === "employee" && (
        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
          <span className="menu-link ">
            <span className="menu-title px-5">
              {" "}
              {intl.formatMessage({ id: "COMMON.EMPMYATTANDANCE" })}
            </span>
            <span className="menu-arrow"></span>
          </span>
          <div className="menu-sub menu-sub-accordion menu-active-bg">
            <div className="menu-item px-5">
              <Link
                to={{
                  pathname: `/attendance/employee/list`,
                  state: { employeeID: user?.employee_id },
                }}
                className="menu-link px-5"
              >
                {intl.formatMessage({ id: "MODULE.ATTANDANCE.MANUALY" })}
              </Link>
            </div>
            <div className="menu-item px-5">
              <Link
                to={{
                  pathname: `/attendance/biometric-logs/employee-logs`,
                  state: { employeeID: user?.employee_id },
                }}
                className="menu-link px-5"
              >
                {intl.formatMessage({ id: "COMMON.BIOMETRICLOG" })}
              </Link>
            </div>
          </div>
        </div>
      )}
      {/* <div className="menu-item px-5">
        <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
          <div className="">
            <div
              className="accordion-header  d-flex"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_1"
            >
              <div className="menu-title px-5">Template</div>
            </div>
            <div
              id="kt_accordion_2_item_1"
              className="fs-6 collapse show ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              <Link to={`/template/sms/`} className="menu-link px-5">
                SMS
              </Link>
              <Link to={`/template/whatsapp/`} className="menu-link px-5">
                Whatsapp
              </Link>
              <Link to={`/template/email/`} className="menu-link px-5">
                Email
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      {userType !== "member" && (
        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
          <span className="menu-link ">
            <span className="menu-title px-5">
              {" "}
              {intl.formatMessage({ id: "COMMON.TEMPLATE" })}
            </span>
            <span className="menu-arrow"></span>
          </span>
          <div className="menu-sub menu-sub-accordion menu-active-bg">
            <div className="menu-item px-5">
              <Link to={`/template/sms/`} className="menu-link px-5">
                {intl.formatMessage({ id: "COMMON.SMS" })}
              </Link>
            </div>
            <div className="menu-item px-5">
              <Link to={`/template/whatsapp/`} className="menu-link px-5">
                {intl.formatMessage({ id: "COMMON.WHATSAPP" })}
              </Link>
            </div>
            <div className="menu-item px-5">
              <Link to={`/template/email/`} className="menu-link px-5">
                {intl.formatMessage({ id: "COMMON.EMAIL" })}
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>"COMMON.MYMEMBERSHIP"
        </a>
      </div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/account/subscription'} className='menu-link px-5'>
        My Subscription
        </Link>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {userType === "member" && (
        <div className="menu-item px-5">
          <Link to="/usermember/membership/list" className="menu-link px-5">
            {intl.formatMessage({ id: "COMMON.MYMEMBERSHIP" })}
          </Link>
        </div>
      )}

      {/* <div className="menu-item px-5 my-1">
        <Link to="/crafted/account/settings" className="menu-link px-5">
          Account Settings
        </Link>
      </div> */}

      <div className="menu-item px-5">
        <Link to="/logout" className="menu-link px-5">
          {intl.formatMessage({ id: "COMMON.SIGNOUT" })}
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
