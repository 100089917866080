import { useFormik } from "formik";
// import "./setting.css";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import data from "../../../../config.json";
import { BiometricCompanyOption, UpdateCompany } from "../redux/settingCRUD";
import Select from "react-select";
import { useEffect, useState } from "react";
const Biometric = ({
  whatsappData,
  setWhatsappData,
  UpdateUser,
  ActiveBranchID,
  ActiveBranchUUID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  setErr,
  ActiveBranchSettingUUID,
}: any) => {
  const [companyOption, setCompanyOption] = useState<any>([]);

  const intl = useIntl();
  const [checked, setChecked] = useState<any>(
    UpdateUser?.BiometricUserDelete === "Yes" ? true : false
  );
  const Schemas = Yup.object().shape({
    BiometricCompany: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.BIO.COMPANY" })),
    BiometricLocation: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.BIO.LOCATION" })),
    BiometricID: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.BIO.MACHINE1" })),
    // secondBiometricID: Yup.string()
    //   .nullable()
    //   .required(intl.formatMessage({ id: "REQ.MODULE.BIO.MACHINE1" })),

    BiometricStatus: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.BIO.STATUS" })),
  });
  const initialState = {
    BiometricID: UpdateUser?.BiometricID,
    secondBiometricID: UpdateUser?.secondBiometricID,
    BiometricCompany: UpdateUser?.BiometricCompany,
    BiometricLocation: UpdateUser?.BiometricLocation,
    BiometricUserDelete: UpdateUser?.BiometricUserDelete,
    BiometricStatus: UpdateUser?.BiometricStatus
      ? UpdateUser?.BiometricStatus
      : "Active",
  };
  useEffect(() => {
    if (UpdateUser && UpdateUser?.BiometricUserDelete) {
      if (UpdateUser?.BiometricUserDelete === "Yes") {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [UpdateUser]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    validationSchema: Schemas,

    onSubmit: async (values) => {
      // console.log(values, "valee");
      setWhatsappData(values);
      const formData = {
        smsCompany: UpdateUser?.smsCompany || "",
        smsUsername: UpdateUser?.smsUsername || "",
        smsPassword: UpdateUser?.smsPassword || "",
        smsSenderID: UpdateUser?.smsSenderID || "",
        smsStatus: UpdateUser?.smsStatus || "",
        smsSupplementStatus: UpdateUser?.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID,
        branchUUID: ActiveBranchUUID,
        courrencyID: UpdateUser?.iCountryCurrencyID || "",
        dataFormate: UpdateUser?.idateFormate || "",
        timeFormate: UpdateUser?.itimeFormate || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: UpdateUser?.InvoiceNotes || "",
        invoiceSupplementNotes: UpdateUser?.suppInvocieNotes || "",
        invoiceprefx: UpdateUser?.inVoicePrefix || "",
        expiredPackageDays: UpdateUser?.expiredListInDays || 0,
        whatsAppCompany: UpdateUser?.whatsAppCompany || "",
        whatsAppUsername: UpdateUser?.whatsUsername || "",
        whatsaAppPassword: UpdateUser?.whatsaAppPassword || "",
        whatsAppStatus: UpdateUser?.whatsAppStatus || "",
        whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus || "",
        SMTPUserName: UpdateUser?.SMTPUserName || "",
        SMTPPassword: UpdateUser?.SMTPPassword || "",
        SMTPHost: UpdateUser?.SMTPHost || "",
        SMTPFromEmail: UpdateUser?.SMTPFromEmail || "",
        SMTPPort: UpdateUser?.SMTPPort || "",
        SMTPSecure: UpdateUser?.SMTPSecure || "",
        SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "",
        SMTPStatus: UpdateUser?.SMTPStatus || "",
        SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus || "",
        BiometricID: values?.BiometricID,
        secondBiometricID: values?.secondBiometricID,
        BiometricCompany: values?.BiometricCompany,
        BiometricLocation: values?.BiometricLocation,
        BiometricUserDelete: values?.BiometricUserDelete || "No",
        BiometricStatus: values?.BiometricStatus,
      };
      const response = await UpdateCompany(formData);
      if (response.status === 200) {
        detailsCompany();
        setWhatsappData(response);
        setSuccessResponse(true);
        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErrorResponse(true);
        setErr(response?.data?.replyMsg);

        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  const biometric_listOfCompany = async () => {
    const response = await BiometricCompanyOption();
    setCompanyOption(response?.data?.data);
    // console.log(response, "biometricList");
  };
  useEffect(() => {
    biometric_listOfCompany();
  }, []);
  const handleToggle = (e: any) => {
    setChecked(!checked);
    if (!checked) {
      //   setState("Yes");
      formik.setFieldValue("BiometricUserDelete", "Yes");
    } else {
      //   setState("No");
      formik.setFieldValue("BiometricUserDelete", "No");
    }
  };
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label htmlFor="whatsAppCompany" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.BIOMETICCOMPANY" })}
              </label>
              <Select
                className="basic-single"
                name="BiometricCompany"
                id="BiometricCompany"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.COMPANY" })}
                value={
                  formik.values.BiometricCompany
                    ? {
                        value: formik.values.BiometricCompany,
                        label: formik.values.BiometricCompany,
                      }
                    : null
                }
                onChange={(options) =>
                  formik.setFieldValue("BiometricCompany", options?.value)
                }
                options={companyOption?.map((item: any) => ({
                  value: item.value,
                  label: item.key,
                }))}
              />
              {formik.errors.BiometricCompany && (
                <div className="error mt-2">
                  {formik.touched.BiometricCompany &&
                    formik.errors.BiometricCompany}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.BIOMETRICID",
                })}
              </label>
              <input
                type="text"
                name="BiometricID"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.MACHINEID",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.BiometricID}
              />
              {formik.errors.BiometricID && (
                <div className="error mt-2">
                  {formik.touched.BiometricID && formik.errors.BiometricID}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.BIOMETRICID2",
                })}
              </label>
              <input
                type="text"
                name="secondBiometricID"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.MACHINEID2",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondBiometricID}
              />
              {/* {formik.errors.secondBiometricID && (
                <div className="error mt-2">
                  {formik.touched.secondBiometricID &&
                    formik.errors.secondBiometricID}
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.BIOMETICLOCATION",
                })}
              </label>
              <input
                type="text"
                name="BiometricLocation"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.LOCATION",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.BiometricLocation}
              />
              {formik.errors.BiometricLocation && (
                <div className="error mt-2">
                  {formik.touched.BiometricLocation &&
                    formik.errors.BiometricLocation}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.BIOMETICDELETE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="BiometricUserDelete"
                  onChange={handleToggle}
                  checked={checked}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsStatus" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.BIOMETICSTATUS" })}
              </label>
              <select
                id="smsStatus"
                name="BiometricStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.BiometricStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
              {formik.errors.BiometricStatus && (
                <div className="error mt-2">
                  {formik.touched.BiometricStatus &&
                    formik.errors.BiometricStatus}
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsSupplementStatus" className="form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.WHATSAPPSUPPLYMENTSTATUS",
                })}
              </label>
              <select
                id="smsSupplementStatus"
                name="whatsAppSupplementStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.whatsAppSupplementStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
            </div>
          </div> */}
        </div>
        <button type="submit" className="btn btn-primary btn-block m-4">
          {intl.formatMessage({
            id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
          })}
        </button>
      </Form>
    </div>
  );
};

export default Biometric;
