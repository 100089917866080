import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_EXPENSE = `${API_URL}/v1/expense/list`;
export const ADD_EXPENSE = `${API_URL}/v1/expense/add`;
export const CREATE_EXPENSECATEGORY = `${API_URL}/v1/expensecategory/listbyBranch`;
export const DETAIL_UPDATE_EXPENSE = `${API_URL}/v1/expense/details`;
export const ACTIVE_EXPENSECATEGORY = `${API_URL}/v1/expensecategory/add`;
export const UPDATE_EXPENSECATEGORY = `${API_URL}/v1/expense/update/`;
export const UPDATE_EXPENSECATEGORY_STATUS = `${API_URL}/v1/expense/update_status`;
export const DELETE_EXPENSECATEGORY = `${API_URL}/v1/expense/delete`;
// Create a department
export function CreateExpenseCategory(body: any) {
  return axios.post(CREATE_EXPENSECATEGORY, body).catch((error) => {
    return error.response;
  });
}
export function activeExpenseCategoryList(activebranchuuid: any) {
  return axios
    .get(`${CREATE_EXPENSECATEGORY}/${activebranchuuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function detailofUpdateExpense(activebranchid: any, expensesuuid: any) {
  return axios
    .get(`${DETAIL_UPDATE_EXPENSE}/${activebranchid}/${expensesuuid}`)
    .catch((error) => {
      return error.response;
    });
}

//add a expense modal
export function addExpense(data: any) {
  return axios.post(ADD_EXPENSE, data).catch((error) => {
    return error.response;
  });
}
// Get all departments
export function GetExpenses(data: any) {
  return axios.post(GET_ALL_EXPENSE, data).catch((error) => {
    return error.response;
  });
}

// Update a department
export function UpdateExpenseCategory(body: any) {
  return axios.put(UPDATE_EXPENSECATEGORY, body).catch((error) => {
    return error.response;
  });
}

// Update department status
export function Update_ExpenseCategory_Status(body: any) {
  return axios.patch(UPDATE_EXPENSECATEGORY_STATUS, body).catch((error) => {
    return error.response;
  });
}
// Delete department
export function Delete_ExpenseCategory(body: any) {
  return axios.patch(DELETE_EXPENSECATEGORY, body).catch((error) => {
    return error.response;
  });
}
