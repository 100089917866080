import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_ENQUIRYSTATUS = `${API_URL}/v1/followup/list`;
export const ACTIVE_ENQUIRY = `${API_URL}/v1/enquiry/listbyBranch`;
export const CREATE_ENQUIRYSTATUS = `${API_URL}/v1/enquirystatus/add`;
export const UPDATE_ENQUIRYSTATUS = `${API_URL}/v1/enquirystatus/update`;
export const UPDATE_ENQUIRYSTATUS_STATUS = `${API_URL}/v1/followup/update_status`;
export const DELETE_ENQUIRYSTATUS = `${API_URL}/v1/followup/delete`;
// Create a department
export function CreateEnquiryStatus(body: any) {
  return axios.post(CREATE_ENQUIRYSTATUS, body).catch((error) => {
    return error.response;
  });
}

// Get all departments
export function GetEnquiryStatus(data?: any) {
  return axios.post(GET_ALL_ENQUIRYSTATUS, data).catch((error) => {
    return error.response;
  });
}
export function enquiryActiveList(data?: any) {
  return axios.get(`${ACTIVE_ENQUIRY}/${data}`).catch((error) => {
    return error.response;
  });
}

// Update a department
export function UpdateEnquiryStatus(body: any) {
  return axios.put(UPDATE_ENQUIRYSTATUS, body).catch((error) => {
    return error.response;
  });
}

// Update department status
export function Update_EnquiryStatus_Status(body: any) {
  return axios.patch(UPDATE_ENQUIRYSTATUS_STATUS, body).catch((error) => {
    return error.response;
  });
}
// Delete department
export function Delete_EnquiryStatus(body: any) {
  return axios.patch(DELETE_ENQUIRYSTATUS, body).catch((error) => {
    return error.response;
  });
}
