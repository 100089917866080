import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import permission from '../../../modules/Genric componant/permissions/permission';
import moment from 'moment';
import { GetEmployees } from '../../../modules/employees/modules/employee/Redux/EmployeeCRUD';
import Pagination from '../../../modules/Genric componant/Pagination';
import * as employee from '../../../modules/employees/modules/employee/Redux/EmployeeRedux'
const showPagelimit = process.env.REACT_APP_PAGELIMIT;

const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);
const TodayBirthdayModal = (selectedDate: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  let activeBranch: any = localStorage.getItem("ActiveBranch");
  let ActiveB = JSON.parse(activeBranch);
  let activeBranchUUID: any = ActiveB.iBranchUUID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("employee");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });



  const [checkedRows, setCheckedRows] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);

  const [TBLData, setTBLData] = useState<any>([]);


  const startDate = selectedDate.selectedDate.startDate;
  const endDate = selectedDate.selectedDate.endDate;

  const employees = useSelector(
    (state: any) => state?.department?.departments?.data
  );
  const selectedbranch: any = useSelector(
    (state: any) => state.auth.selectedBranch
  );

  let initialState = {
    branchID: selectedbranch ? selectedbranch.iBranchID : ActiveB?.iBranchID,
    iEmployeeUUID: "",
    status: "",
    page_limit: pageLimit,
    page_no: 1,
    search: "",
    start_date: startDate,
    end_date: endDate,
    startDateofjoin: "",
    endDateofjoin: "",
    startDateofresign: "",
    endDateofresign: "",
    roleUUID: "",
    iDeptID: "",
    iDesignID: "",
    eEGender: "",
    marital_status: "",
    blood_group: "",
    order_key: "iBranchID",
    order_value: "desc",
    date_type: "DOB",
  };

  const [filterValues, setFilterValues] = useState(initialState);
  const [dateFormat, setdateFormat] = useState<any>(ActiveB.idateFormate);

  useEffect(() => {
    getEmployees();
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    setFilteredData(employees?.data);
    handleExports();
  }, [employees?.data]);

  const getEmployees = () => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetEmployees({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(employee.actions.GetDepartments(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  };

  const handleExports = () => {
    const tableRows = employees?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [intl.formatMessage({
          id: "MODULE.EMPLOYEE.BIOMETRIC",
        })]: element?.biometric_employee_id,
        [intl.formatMessage({ id: "MODULE.EMPLOYEE" })]: element?.vEFirstname,
        [intl.formatMessage({ id: "COMMON.MOBILENUMBER" })]:
          element.vEmpHomeCountryCode + "-" + element.vEPhoneno,
        [intl.formatMessage({ id: "COMMON.DEPARTMENTS" })]: element?.vDeptname,
        [intl.formatMessage({ id: "COMMON.DESIGNATIONS" })]:
          element?.vDesignname,
        [intl.formatMessage({ id: "COMMON.EMAIL" })]: element?.vEEmail,
        [intl.formatMessage({ id: "COMMON.DOB" })]: element?.dtEDOB,
        [intl.formatMessage({ id: "COMMON.DOJ" })]: element?.dtEJoin,
        [intl.formatMessage({ id: "COMMON.STATUS" })]:
          element.eStatus === 1
            ? intl.formatMessage({ id: "COMMON.ACTIVE" })
            : intl.formatMessage({ id: "COMMON.INACTIVE" }),
      };
    });

    setTBLData(tableRows);
  };

  const [show, setShow] = useState<any>(false);
  const handleShow = () => setShow(true);

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  return (

    <>
      <div className='w-25'>
        <span
          className="min-w-40px mb-3 d-flex align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card-body py-3">
        {/* {isLoading && <Loading />} */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                {/* <th className=" mobilewidth">
                <div className="mobileViewalign w-100">
                  <div className=" selectAll ">
                    {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                  </div>
                  <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      data-kt-check="true"
                      data-kt-check-target=".widget-9-check"
                      checked={isAllRowsChecked}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </div>
                </div>
              </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="biometric_employee_id"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.EMPLOYEE.BIOMETRIC",
                    })}
                    {sortConfig.key !== "biometric_employee_id" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "biometric_employee_id" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "biometric_employee_id" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vEFirstname"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "MODULE.EMPLOYEE" })}
                    {sortConfig.key !== "vEFirstname" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vEFirstname" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vEFirstname" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vEPhoneno"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.MOBILENUMBER" })}
                    {sortConfig.key !== "vEPhoneno" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vEPhoneno" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vEPhoneno" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vDeptname"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DEPARTMENTS" })}
                    {sortConfig.key !== "vDeptname" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vDeptname" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vDeptname" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vDesignname"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DESIGNATIONS" })}
                    {sortConfig.key !== "vDesignname" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vDesignname" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vDesignname" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>

                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vEEmail"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.EMAIL" })}
                    {sortConfig.key !== "vEEmail" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vEEmail" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vEEmail" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtEDOB"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DOB" })}
                    {sortConfig.key !== "dtEDOB" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtEDOB" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtEDOB" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtEJoin"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DOJ" })}
                    {sortConfig.key !== "dtEJoin" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtEJoin" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtEJoin" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.STATUS" })}
                    {sortConfig.key !== "eStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  {intl.formatMessage({ id: "COMMON.ACTIONS" })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {filteredData && filteredData.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  const isChecked = checkedRows.includes(
                    element.iEmployeeUUID
                  );
                  return (
                    <>
                      <tr key={index} className="fs-5">
                        {/* <td
                        data-label={intl.formatMessage({
                          id: "COMMON.SELECT",
                        })}
                      >
                        <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                          <input
                            className="form-check-input widget-9-check"
                            type="checkbox"
                            value="1"
                            checked={isChecked}
                            onChange={() =>
                              handleRowCheckboxChange(
                                element.iEmployeeUUID,
                                element.iBranchID
                              )
                            }
                          />
                        </div>
                      </td> */}
                        <td
                          data-label={intl.formatMessage({
                            id: "MODULE.EMPLOYEE.BIOMETRIC",
                          })}
                        >
                          {element.biometric_employee_id}
                        </td>
                        <td
                          data-label={intl.formatMessage({
                            id: "MODULE.EMPLOYEE",
                          })}
                        >
                          {element.vEFirstname}
                        </td>
                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.MOBILENUMBER",
                          })}
                        >
                          <a
                            href={`tel:${element.vEmpCountryCode}-${element.vEPhoneno}`}
                          >
                            {element.vEmpCountryCode}-{element.vEPhoneno}
                          </a>
                        </td>
                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.DEPARTMENTS",
                          })}
                        >
                          {element.vDeptname}
                        </td>
                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.DESIGNATIONS",
                          })}
                        >
                          {element.vDesignname}
                        </td>

                        {/* <td
                          data-label={intl.formatMessage({
                            id: "COMMON.EMAIL",
                          })}
                        >
                          <a href={`mailto:${element.vEEmail}`}>
                            {element.vEEmail}
                          </a>
                        </td> */}

                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.DOB",
                          })}
                        >
                          {/* {element.dtEDOB} */}
                          {element.dtEDOB &&
                            moment(element.dtEDOB).format(dateFormat)}
                        </td>

                        {/* <td
                          data-label={intl.formatMessage({
                            id: "COMMON.DOJ",
                          })}
                        >
                          {moment(element.dtEJoin).format(dateFormat)}
                        </td>

                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.STATUS",
                          })}
                        >
                          {StatusBadge(element.eStatus)}
                        </td> */}

                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.ACTIONS",
                          })}
                        >
                          <button className='btn btn-primary'>send</button>
                          {/* <div className="d-flex flex-shrink-0 floatRight ">
                          <a
                            href="#g"
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${edit === 0 &&
                              UserType === "employee" &&
                              "d-none"
                              }`}
                            onClick={() => {
                              setisUpdate(true);
                              setEmplItem(element);
                              handleUpdate(
                                element.iBranchID,
                                element.iEmployeeUUID
                              );
                              setShow(true);
                            }}
                          >
                            <KTSVG
                              path="/media/icons/duotune/art/art005.svg"
                              className="svg-icon-3"
                            />
                          </a>
                        </div> */}
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                {/* <th className=" mobilewidth">
                <div className="mobileViewalign w-100">
                  <div className=" selectAll ">
                    {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                  </div>
                  <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      data-kt-check="true"
                      data-kt-check-target=".widget-9-check"
                      checked={isAllRowsChecked}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </div>
                </div>
              </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="biometric_employee_id"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.EMPLOYEE.BIOMETRIC",
                    })}
                    {sortConfig.key !== "biometric_employee_id" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "biometric_employee_id" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "biometric_employee_id" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vEFirstname"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "MODULE.EMPLOYEE" })}
                    {sortConfig.key !== "vEFirstname" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vEFirstname" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vEFirstname" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vEPhoneno"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.MOBILENUMBER" })}
                    {sortConfig.key !== "vEPhoneno" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vEPhoneno" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vEPhoneno" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vDeptname"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DEPARTMENTS" })}
                    {sortConfig.key !== "vDeptname" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vDeptname" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vDeptname" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vDesignname"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DESIGNATIONS" })}
                    {sortConfig.key !== "vDesignname" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vDesignname" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vDesignname" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>

                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vEEmail"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.EMAIL" })}
                    {sortConfig.key !== "vEEmail" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "vEEmail" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "vEEmail" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtEDOB"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DOB" })}
                    {sortConfig.key !== "dtEDOB" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtEDOB" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtEDOB" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtEJoin"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.DOJ" })}
                    {sortConfig.key !== "dtEJoin" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtEJoin" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtEJoin" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.STATUS" })}
                    {sortConfig.key !== "eStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  {intl.formatMessage({ id: "COMMON.ACTIONS" })}
                </th>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin:: pagination  */}
        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  )
}

export default TodayBirthdayModal