import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import swal from "sweetalert";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-phone-input-2/lib/style.css";
import {
  ActiveTax,
  enrollListofRenewal,
  enrollDetailsofRenewal,
  Renewal,
  // activeUserList,
} from "../../redux/MembersCRUD";

import { useIntl } from "react-intl";

const RenewalModal = ({
  renewalShow,
  setRenewalShow,
  memberUID,
  // UUID,
  activeBatchList,
  activeUser,
}: any) => {
  const intl = useIntl();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  // console.log("activebranch", ActiveBranch);
  const ActiveBranchID = ActiveBranch.iBranchID;
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;

  const [selectedPackageDetail, setSelectedPackageDetail] = useState<any>({});
  // const [activeUser, setActiveUser] = useState<any>([]);
  // const [withoutFixDiscount, setWithoutFixDiscount] = useState(0);
  const [withOutTaxRadio, setwithOutTaxRadio] = useState<any>(false);
  const [withTaxRadio, setwithTaxRadio] = useState<any>(false);
  // const [isCheckedRegFee, setIsCheckedRegFee] = useState<boolean>();
  const [discountType, setDiscountType] = useState<any>({
    value: 1,
    label: "None",
  });
  // const [activeUser, setActiveUser] = useState([]);
  const [fixDiscount, setFixDiscount] = useState<number>(0);
  const [FeeAPI, setFeeAPI] = useState<any>("");
  const [taxExcluded, setTaxExcluded] = useState<boolean>();
  const [taxIncluded, setTaxIncluded] = useState<boolean>(false);
  const [tax, setTax] = useState<any>([]);
  const [taxValue, setTaxValue] = useState<number>();
  const [fee, setFee] = useState<number>(0);
  const [expiryDate, setexpiryDate] = useState<any>();
  const [startDate, setstartDate] = useState<any>();
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  const [programFee, setProgramFee] = useState<any>();
  const [payableFee, setPayableFee] = useState<any>();
  const [selectedmember, setSelectedMember] = useState({
    label: "Select a Member",
    value: "",
  });
  const [batchid, setBatchID] = useState<any>({
    value: "",
    label: "Select Batch",
  });
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [packageRenewal, setPackageRenewal] = useState([]);
  // const [taxDetails, setTaxDetails] = useState<any>([]);
  const [packageName, setPackageName] = useState("");
  const [selectedPackagesrenew, setSelecetedMemberrenew] = useState({
    label: "Select a package",
    value: "",
  });
  const [registrationFees, setRegistraionFees] = useState<number>(0);
  const [joinDate, setJoinDate] = useState<any>();
  const [submitted, setSubmitted] = useState(false);

  // yup validation schema

  const Schemas = Yup.object().shape({
    packageUUID: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.PACKAGES" })}`
    ),
    enrollUUID: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.MEMBER" })}`
    ),
    dateofStarting: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.FROZONDATE.STARTINGDATE" })}`
    ),
    dateofJoining: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.FROZONDATE.JOINDATE" })}`
    ),
  });
  // create member value
  let createValue = {
    branchID: ActiveBranchID,
    memberUUID: memberUID,
    packageUUID: "",
    withTaxStatus: "",
    taxno: "",
    taxdetails: "",
    dateofJoining: "",
    dateofStarting: "",
    batchID: "",
    registrationFeeAllow: 0,
    discountType: 1,
    discount: "",
    status: "1",
    enrollUUID: "",
  };

  //define discount type
  const discountTypeOptions = [
    { value: 1, label: "None" },
    { value: 2, label: "Complementry" },
    { value: 3, label: "Fix Discount" },
  ];
  //Reset form
  // const ResetForm = () => {
  //   setSelectedPackage({
  //     value: "",
  //     label: "Select Package",
  //   });

  //   setProgramFee(0);
  //   setDiscountType({ value: "", label: "select a discount" });
  //   setFixDiscount(0);
  //   setDiscountValue(0);
  //   setFee(0);
  //   taxReset();

  //   formik.setFieldValue("packageUUID", "");
  //   formik.setFieldValue("batchID", "");
  //   setBatchID({
  //     value: "",
  //     label: "Select Batch",
  //   });
  // };

  //call the function
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createValue,

    validationSchema: Schemas,

    onSubmit: async (values, action) => {
      // console.log("values", values);
      if (submitted) {
        const response = await Renewal(values);
        // console.log("res121", response);
        if (response.status === 200) {
          swal(response?.data?.replyMsg, { icon: "success" });
          setRenewalShow(false);
          // setSelectedMember({
          //   value: "",
          //   label: "select a member",
          // });
          setSelectedMember({
            value: "",
            label: `${intl.formatMessage({
              id: "MODULE.SELECTPACKAGE.MEMBER",
            })}`,
          });
          handleResetForm();
        } else {
          swal(response?.data?.replyMsg, { icon: "error" });
        }
      }
    },
  });
  // console.log(memberUID, "memberUUID");
  const handleClose = () => {
    setRenewalShow(false);
    setSelectedMember({
      value: "",
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.MEMBER",
      })}`,
    });
    handleResetForm();
    formik.setFieldValue("enrollUUID", "");
    formik.setFieldValue("packageUUID", "");
  };

  // handleResettax
  const taxReset = () => {
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTaxExcluded(false);
    setTaxValue(0);
    setPayableFee(0);
  };
  useEffect(() => {
    packageList();
  }, [memberUID]);

  // const showMember = async () => {
  //   const response = await activeUserList(UUID);
  //   setActiveUser(response?.data?.data);
  //   console.log("12322", response);
  // };
  const packageList = async () => {
    const response = await enrollListofRenewal(ActiveBranchID, memberUID);
    setPackageRenewal(response?.data?.data);
    // console.log("ressss", response);
  };

  // packageSelectionByEnrollId
  const handleMemberChange = async (Event: any) => {
    setSelectedMember({
      value: Event.value,
      label: Event.label,
    });
    formik.setFieldValue("memberUUID", Event.value);
    //   console.log("faiza", Event.value);

    const response = await enrollListofRenewal(ActiveBranchID, Event.value);
    setPackageRenewal(response?.data?.data);
    // console.log("ressss", response);
    handleResetForm();
  };

  const findDiscountLabel = (value: number) => {
    const option = discountTypeOptions.find((option) => option.value === value);
    return option ? option.label : null;
  };

  //  packagedetails
  const renewPackagechange = async (Event: any) => {
    setSelecetedMemberrenew({
      value: Event.value,
      label: Event.label,
    });

    const response = await enrollDetailsofRenewal(ActiveBranchID, Event.value);
    // console.log("dsafas", response);

    setSelectedPackageDetail(response?.data?.data);

    const data = response?.data?.data;
    console.log(data, "spoortuuid");
    formik.setFieldValue("memberUUID", data?.iSpotUUID);
    formik.setFieldValue("enrollUUID", data?.iEnrolUUID);
    formik.setFieldValue("packageUUID", data?.packageUUID);
    // console.log("disco", data);
    setPackageName(data?.packageName);
    setDiscountType({
      value: data?.discountType,
      label: findDiscountLabel(data?.discountType),
    });
    formik.setFieldValue("discountType", data?.discountType);
    setRegistraionFees(data?.registrationFee);

    setProgramFee(data?.packageFee);
    if (data?.discountType == 3) {
      setFixDiscount(data?.discountAmt.split(" ")[0]);
      formik.setFieldValue("discount", data?.discountAmt.split(" ")[0]);
    } else {
      setDiscountValue(data?.discountAmt.split(" ")[0]);
      formik.setFieldValue("discount", data?.discountAmt.split(" ")[0]);
    }
    if (data?.discountType == 2) {
      setFee(0);
    }
    setFee(data?.feeWithoutTax);
    setFeeAPI(data?.feeWithoutTax);
    setstartDate(data?.dtStartingdate.split("T")[0]);
    getTodayDate(data?.iDays);
    setJoinDate(data?.dtJoin.split("T")[0]);
    formik.setFieldValue("dateofJoining", data?.dtJoin.split("T")[0]);

    // console.log("dateofjoi", joinDate);
  };

  const handleResetForm = () => {
    setSelectedPackageDetail([]);
    setSelecetedMemberrenew({
      value: "",
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.ENROLMENTLIST",
      })}`,
    });
    setDiscountType({
      value: "",
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.DISCOUNT",
      })}`,
    });
    setPackageName("");
    setRegistraionFees(0);
    setFixDiscount(0);
    setFee(0);
    setstartDate("");
    setProgramFee(0);
    setDiscountValue(0);
    setJoinDate(null);
    taxReset();
    setSubmitted(false);
    setBatchID("");
    // formik.setFieldValue("enrollUUID", "");
    // formik.setFieldValue("packageUUID", "");
    formik.resetForm();
    // formik.setFieldValue("enrollUUID", "");
  };
  // const getMemberList = async () => {
  //   const response = await GetMemberDetailsRenewal();
  // };

  //start handle package
  // const handlePackageChange = async (Event: any) => {
  //   formik.setFieldValue("packageUUID", Event.value);
  //   setSelectedPackage({
  //     value: Event.value,
  //     label: Event.label,
  //   });
  //   taxReset();

  //   await getPackageDetails(ActiveBranchID, Event.value);
  // };

  //end handle package

  //start get package details
  // const getPackageDetails = async (branchID: any, iProgramUUID: any) => {
  //   const response = await GetPackageDetails(branchID, iProgramUUID);
  //   console.log("ress", response);

  //   setSelectedPackageDetail(response?.data?.data);

  //   setProgramFee(response?.data?.data?.fProgramFees);
  //   setFee(response?.data?.data?.fProgramFees);

  //   setDiscountType({ value: 1, label: "None" });

  //   getTodayDate(response?.data?.data?.iDays);
  // };
  //end get package details
  // handle Reset

  //start handle discount type
  const handleDiscountType = (Event: any) => {
    formik.setFieldValue("discountType", Event.value);
    setDiscountType(Event.value);
    taxReset();
    const isregistraionfee = registrationFees == 0 ? 0 : registrationFees;
    if (Event.value == 1) {
      setDiscountType({ value: Event.value, label: Event.label });
      setDiscountValue(0);
      formik.setFieldValue("discount", 0);
      setFixDiscount(0);
      setFee(isregistraionfee + programFee);
    } else if (Event.value == 3) {
      setDiscountType({ value: Event.value, label: Event.label });

      setFixDiscount(0);

      // console.log("registraion", isregistraionfee);
      setFee(isregistraionfee + programFee);
    } else {
      setDiscountType({ value: 2, label: "Complementry" });
      setDiscountValue(isregistraionfee + programFee);
      formik.setFieldValue("discount", programFee);
      setFee(0);
    }
  };
  //end handle discount type

  //start handle fix discount type
  const handleFixDiscount = (Event: any) => {
    setFixDiscount(Event.target.value);
    setDiscountValue(0);

    formik.setFieldValue("discount", Event.target.value);
    if (Event.target.value === "") {
      setFee(FeeAPI);
    } else {
      const packageFee = selectedPackageDetail?.fProgramFees;
      // console.log("feewithout", fee);
      setFee(FeeAPI - Event.target.value);
    }

    if (Event.target.value > programFee) {
      swal("Discount amount is not greater than total fee amont", {
        icon: "error",
      });
      setFee(FeeAPI);
    }
  };

  //end handle fix discount type

  //start handle tax excluded
  const handleTaxExcluded = (Event: any) => {
    setTaxIncluded(false);
    setSubmitted(true);
    setTaxValue(0);
    setPayableFee(fee);
    setwithTaxRadio(false);
    setwithOutTaxRadio(true);
    setTax([]);
    // formik.setFieldValue("taxdetails", 0);
    formik.setFieldValue("taxdetails", []);
    formik.setFieldValue("withTaxStatus", 0);
  };
  //end handle tax excluded

  //start define tax incliude function
  const handleTaxIncluded = () => {
    setTaxIncluded(true);
    setSubmitted(true);
    setwithTaxRadio(true);
    setwithOutTaxRadio(false);
    // formik.setFieldValue("taxType", false);
    formik.setFieldValue("withTaxStatus", 1);
  };
  //end define tax incliude function

  useEffect(() => {
    if (taxIncluded) {
      activeTax(ActiveBranchUUID);

      //  calculateTaxValue();
    }
  }, [taxIncluded]);
  useEffect(() => {
    if (tax && tax.length > 0) {
      calculateTaxValue();
    }
  }, [tax, taxIncluded]);

  //start define active tax function
  const activeTax = async (branchUUID: any) => {
    try {
      const response = await ActiveTax(branchUUID);
      const taxData = response?.data?.data;
      setTax(taxData);
      formik.setFieldValue("taxdetails", taxData);
    } catch (error) {
      // console.error("Error fetching active tax:", error);
    }
  };
  //end define active tax function

  //start calculateTaxValue function
  const calculateTaxValue = () => {
    let total = 0;
    tax.forEach((item: any) => {
      total += (item.taxRate * fee) / 100;
    });
    total = Math.floor(total);
    setTaxValue(total);
    let payableFees = fee + total;
    setPayableFee(payableFees);
  };
  //end calculateTaxValue function

  //start getTodayDate function
  const getTodayDate = (days: any) => {
    const newDate = moment(new Date()).format(dateFormat);
    let todayDate = newDate;
    // formik.setFieldValue("dateofJoining", newDate);
    if (startDate) {
      todayDate = startDate;
    }
    formik.setFieldValue("dateofStarting", todayDate);
    // setstartDate(todayDate);
    const newExpiredDate = moment(todayDate)
      .add(days, "days")
      .format(dateFormat);
    setexpiryDate(newExpiredDate);
  };
  //end getTodayDate function

  //start handle start date change
  const handledStartDateChange = (Event: any) => {
    const selectedStartedDate = Event.target.value;
    formik.setFieldValue("dateofStarting", selectedStartedDate);
    setstartDate(Event.target.value);
    let packageDays = selectedPackageDetail?.iDays;
    // console.log("packageDays===", packageDays);
    const newExpiredDate = moment(selectedStartedDate)
      .add(packageDays, "days")
      .format(dateFormat);
    // console.log("newExpiredDate===", newExpiredDate);
    setexpiryDate(newExpiredDate);
  };

  const handleBatchID = (Event: any) => {
    formik.setFieldValue("batchID", Event.value);
    setBatchID({
      value: Event.value,
      label: Event.label,
    });
  };
  // const handleSubmit(){

  // }
  const sno = intl.formatMessage({ id: "MODULE.MEMBER.SNO" });
  const taxname = intl.formatMessage({ id: "MODULE.MEMBER.TAXNAME" });
  const amount = intl.formatMessage({ id: "MODULE.MEMBER.AMOUNT" });

  return (
    <>
      <Modal
        show={renewalShow}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`${intl.formatMessage({
              id: "MODULE.MEMBER.Renewal.PACKAGE",
            })}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body  mx-2 mx-xl-2 my-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex flex-column scroll-y me-n7 pe-7">
              <div className="p-6.5">
                <div className="row mb-6 mt-7">
                  {!memberUID && (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="enrollUUID"
                          className="required form-label"
                        >
                          {intl.formatMessage({
                            id: "MODULE.RENEWAL.MEMBER.ENROLL",
                          })}
                        </label>
                        <Select
                          id="enrollUUID"
                          name="enrollUUID"
                          className="basic-single"
                          value={selectedmember}
                          options={activeUser?.map((item: any) => ({
                            value: item.iSpotUUID,
                            label: `${item.name} (${item.countryCode}-${item.mobileno})`,
                          }))}
                          onChange={(e) => handleMemberChange(e)}
                        />
                        {formik.touched.enrollUUID &&
                          formik.errors.enrollUUID && (
                            <div className="text-danger">
                              {formik.errors.enrollUUID}
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="packageUUID"
                        className="required form-label"
                      >
                        {intl.formatMessage({
                          id: "MODULE.RENEWAL.PACKAGE.MEMBER",
                        })}
                      </label>
                      <Select
                        id="packageUUID"
                        name="packageUUID"
                        className="basic-single"
                        value={selectedPackagesrenew}
                        options={packageRenewal?.map((item: any) => ({
                          value: item.iEnrolUUID,
                          label: `${item.eEnrol_Type} (${item.packageName}) `,
                        }))}
                        onChange={(e) => renewPackagechange(e)}
                      />
                      {formik.touched.packageUUID &&
                        formik.errors.packageUUID && (
                          <div className="text-danger">
                            {formik.errors.packageUUID}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={{ color: "#009ef7", textDecoration: "underline" }}>
                    {intl.formatMessage({
                      id: "MODULE.MEMBERS.PACKAGEDETAILS",
                    })}
                  </h1>
                </div>
                <div className="row mb-6 mt-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="packageUUID" className="form-label">
                        {intl.formatMessage({ id: "MODULE.MEMBERS.PACKAGE" })}
                      </label>
                      <input
                        id="packageUUID"
                        name="packageUUID"
                        className="form-control"
                        value={packageName}
                        // options={activePackageList?.map((item: any) => ({
                        //   value: item.iProgramUUID,
                        //   label: item.vProgram,
                        // }))}
                        disabled
                      />
                      {/* {formik.touched.packageUUID &&
                        formik.errors.packageUUID && (
                          <div className="text-danger">
                            {formik.errors.packageUUID}
                          </div>
                        )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="registrationFee" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.REGISTRATIONFEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="registrationFee"
                        name="registrationFee"
                        className="form-control"
                        value={registrationFees}
                        // onChange={formik.handleChange("programfee")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="programfee" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.PACKAGEFEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="programfee"
                        name="programfee"
                        className="form-control"
                        value={programFee}
                        // onChange={formik.handleChange("programfee")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-4">
                    <label
                      htmlFor="department_name"
                      className="required form-label"
                    >
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DISCOUNTTYPE",
                      })}
                    </label>
                    <Select
                      className=" basic-single"
                      id="discountType"
                      name="discountType"
                      value={discountType}
                      options={discountTypeOptions.map((item: any) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      onChange={(e) => handleDiscountType(e)}
                    />
                    {/* {console.log(
                      "discount",
                      formik.touched.discount,
                      formik.errors
                    )} */}
                    {/* {formik.touched.discountType &&
                      formik.errors.discountType && (
                        <div className="text-danger">
                          {formik.errors.discountType}
                        </div>
                      )} */}
                  </div>
                  {discountType.label === "Fix Discount" && (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.FIXDISCOUNT",
                          })}
                        </label>
                        <input
                          type="number"
                          id="fixdiscount"
                          name="fixdiscount"
                          className="form-control"
                          value={fixDiscount}
                          onChange={(e) => handleFixDiscount(e)}
                        />
                      </div>
                    </div>
                  )}
                  {discountType.label !== "Fix Discount" && (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DISCOUNT",
                          })}
                        </label>
                        <input
                          type="number"
                          id="discount"
                          name="discount"
                          className="form-control"
                          onChange={formik.handleChange("discount")}
                          value={discountValue}
                          // value={formik.values.discount}
                          disabled
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-4 mt-2">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.FEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="fee"
                        name="fee"
                        className="form-control"
                        value={fee}
                        // value={formik.values.fee}
                        // onChange={formik.handleChange("fee")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="taxType" className="form-label required">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.TAX",
                        })}
                      </label>
                      <div>
                        <input
                          name="withTaxStatus"
                          type="radio"
                          id="taxexcluded"
                          checked={withOutTaxRadio}
                          value={withOutTaxRadio}
                          onChange={handleTaxExcluded}
                        />
                        <label style={{ marginLeft: "3px" }}>
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.WITHOUTTAX",
                          })}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="withTaxStatus"
                          id="taxincluded"
                          checked={withTaxRadio}
                          value={withTaxRadio}
                          onChange={handleTaxIncluded}
                        />
                        <label style={{ marginLeft: "3px" }}>
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.WITHTAX",
                          })}
                        </label>
                      </div>
                    </div>
                    {submitted == false ? (
                      <div className="text-danger">Tax is required</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.TAXVALUE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="taxvalue"
                        name="taxvalue"
                        className="form-control"
                        value={taxValue}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="payableFee" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.PAYABLEFEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="payableFee"
                        name="payableFee"
                        className="form-control"
                        value={payableFee}
                        // value={formik.values.payableFee}
                        onChange={formik.handleChange("payableFee")}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-7 mt-2 ">
                    {taxIncluded && (
                      <div className="col-md-4  border border-primary">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr>
                                <th className="notVisibleInMobile">{sno}</th>
                                <th className="notVisibleInMobile">
                                  {taxname}
                                </th>
                                <th className="notVisibleInMobile">{amount}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tax.map((item: any, index: any) => (
                                <tr key={index}>
                                  <td data-label={sno}>{index + 1}</td>
                                  <td
                                    data-label={taxname}
                                  >{`${item.taxName} (${item.rate}%)`}</td>
                                  <td data-label={amount}>
                                    {(item.rate * fee) / 100}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {taxIncluded && (
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="taxno" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.TAX.NUMBER",
                            })}
                          </label>
                          <input
                            type="text"
                            id="taxno"
                            name="taxno"
                            className="form-control"
                            onChange={formik.handleChange("taxno")}
                            value={formik.values.taxno}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-4">
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.BATCH",
                        })}
                      </label>

                      <Select
                        className=" basic-single"
                        id="batchID"
                        name="batchID"
                        value={batchid}
                        options={activeBatchList.map((item: any) => ({
                          value: item.iBatchID,
                          label: item.batchName,
                        }))}
                        onChange={(e) => handleBatchID(e)}
                      />
                    </div>
                    {/* <div className="col-md-4">
                      <input
                        type="hidden"
                        id="taxdetails"
                        name="taxdetails"
                        className="form-control"
                        // value={formik.values.name}
                        value={formik.values.taxdetails}
                        onChange={formik.handleChange("taxdetails")}
                      />
                    </div> */}
                  </div>
                  {/* <div className="row mb-7 mt-2"> </div> */}
                  <div className="row mb-7 mt-2">
                    <h1
                      className="mb-5"
                      style={{ color: "#009ef7", textDecoration: "underline" }}
                    >
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATEDETAILS",
                      })}
                    </h1>
                    <div className="col-md-4 " style={{ height: "80px" }}>
                      <label
                        htmlFor="dateofJoining"
                        className="required form-label"
                      >
                        {intl.formatMessage({ id: "MODULE.MEMBERS.DOJ" })}
                      </label>
                      <input
                        type="date"
                        id="dateofJoining"
                        name="dateofJoining"
                        className="form-control"
                        // onChange={formik.handleChange("dateofJoining")}
                        value={joinDate}
                        onChange={(e) => setJoinDate(e.target.value)}
                        // onChange={(e) => handledobChange(e)}
                      />
                      {formik.touched.dateofJoining &&
                        formik.errors.dateofJoining && (
                          <div className="text-danger">
                            {formik.errors.dateofJoining}
                          </div>
                        )}
                    </div>
                    <div className="col-md-4 " style={{ height: "80px" }}>
                      <label
                        htmlFor="department_name"
                        className="required form-label"
                      >
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.STARTINGDATE",
                        })}
                      </label>
                      <input
                        type="date"
                        id="dateofStarting"
                        name="dateofStarting"
                        className="form-control"
                        //onChange={formik.handleChange("dateofStarting")}
                        value={startDate}
                        onChange={(e) => handledStartDateChange(e)}
                      />
                      {formik.touched.dateofStarting &&
                        formik.errors.dateofStarting && (
                          <div className="text-danger">
                            {formik.errors.dateofStarting}
                          </div>
                        )}
                    </div>
                    <div className="col-md-4 ">
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.EXPIRYDATE",
                        })}
                      </label>

                      <input
                        type="date"
                        id="dateofStarting"
                        name="dateofexpiry"
                        className="form-control"
                        value={expiryDate}
                        disabled
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          onChange={formik.handleChange("status")}
                          onBlur={formik.handleBlur("status")}
                          value={formik.values.status}
                        >
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-4"
                >
                  {`${intl.formatMessage({
                    id: "MODULE.RENEWAL.SUBMIT",
                  })}`}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RenewalModal;
