import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import Pagination from '../../../modules/Genric componant/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import permission from '../../../modules/Genric componant/permissions/permission';
import moment from 'moment';
import Dummy from "../../../../loading_assests/dummy.png";
import { GetMembers } from '../../../modules/members/redux/MembersCRUD';
import * as member from "../../../modules/members/redux/MembersRedux";

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);
const TodayMemberBirthdayModal = (selectedDate: any) => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "desc", // Default sorting direction
  });
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const activeBranchID = ActiveBranch.iBranchID;
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;

  const dispatch = useDispatch();
  const members: any = useSelector(
    (state: any) => state?.members?.members?.data
  );

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("members");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const firstColumnTitle = `${intl.formatMessage({
    id: "COMMON.BIOMETRIC",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.MEMBER",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.MOBILE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.GENDER",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.EMPLOYEE",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.BATCH",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.STARTDATE",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENDDATE",
  })}`;

  const tenthColumnTitle = `${intl.formatMessage({
    id: "COMMON.STATUS",
  })}`;
  const eleventhColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;
  const twelthColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const packageFee = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGEFEE",
  })}`;
  const DueAmount = `${intl.formatMessage({
    id: "MODULE.MEMBERS.DUOAMT",
  })}`;

  const startDate = selectedDate.selectedDate.startDate;
  const endDate = selectedDate.selectedDate.endDate;
  let initialState = {
    branchID: activeBranchID,
    status: "",
    page_limit: pageLimit,
    // page_no: 1,
    employeeID: "",
    occuptionID: "",
    gender: "",
    maritalStatus: "",
    batchID: "",
    packageUUID: "",
    packageGroupUUID: "",
    search: "",
    start_date: startDate,
    end_date: endDate,
    order_key: " iSpotID",
    order_value: "desc",
    date_type: "DOB",
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateFormat, setdateFormat] = useState<any>(ActiveBranch?.idateFormate);
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [filterValues, setFilterValues] = useState(initialState);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [baseURL, setBaseURL] = useState<any>([]);
  const [TBLData, setTBLData] = useState<any>([]);

  const handleExports = () => {
    const tableRows = members?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [firstColumnTitle]: element?.biometric_member_id,
        [secondColumnTitle]: element.name,
        [thirdColumnTitle]: element.countryCode + " " + element.mobileNo,
        [fourthColumnTitle]: element?.eGender,
        [sixthColumnTitle]: element?.employeeName,
        [packageFee]: element?.packageName,
        [DueAmount]: element?.dueAmt,
        [seventhColumnTitle]: element?.batchName,
        [eigthColumnTitle]: element?.dtStartingdate,
        [ninthColumnTitle]: element?.dtExpirydate,
        [tenthColumnTitle]:
          element.eStatus === 1
            ? intl.formatMessage({ id: "COMMON.ACTIVE" })
            : intl.formatMessage({ id: "COMMON.INACTIVE" }),
      };
    });
    setTBLData(tableRows);
  };



  const getMembers = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetMembers({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        if (data?.status !== 200) {
          setFilteredData([]);
        } else {
          dispatch(member.actions.GetMembers(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setBaseURL(members?.imageBaseURL);
    setFilteredData(members?.data);
    handleExports();
  }, [members?.data]);


  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));

    setPage(1);
  };
  return (
    <>
      <div className='w-25'>
        <span
          className="min-w-40px mb-3 d-flex align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card-body py-3">
        {/* {isLoading && <Loading />} */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                {/* <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={(e) => handleSelectAllCheckboxChange(e)}
                        />
                      </div>
                    </div>
                  </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="biometric_member_id"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "biometric_member_id" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "biometric_member_id" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "biometric_member_id" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {secondColumnTitle}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eGender"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "eGender" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eGender" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eGender" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="employeeName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "employeeName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "employeeName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "employeeName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="payableFee"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {packageFee}
                      {sortConfig.key !== "payableFee" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${sortConfig.key == "payableFee" &&
                              sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                              }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${sortConfig.key == "payableFee" &&
                              sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                              }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {DueAmount}
                      {sortConfig.key !== "dueAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${sortConfig.key == "dueAmt" &&
                              sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                              }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${sortConfig.key == "dueAmt" &&
                              sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                              }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="batchName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "batchName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtStartingdate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "dtStartingdate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtExpirydate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "dtExpirydate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {tenthColumnTitle}
                    {sortConfig.key !== "eStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  {eleventhColumnTitle}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredData && filteredData?.length === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  const isChecked = checkedRows.includes(element.iSpotUUID);
                  // console.log("uidofmember", element);
                  return (
                    <>
                      <tr key={index} className="fs-5">
                        {/* <td
                          data-label={intl.formatMessage({
                            id: "COMMON.SELECT",
                          })}
                        >
                          <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value="1"
                              checked={isChecked}
                              onChange={() =>
                                handleRowCheckboxChange(element.iSpotUUID)
                              }
                            />
                          </div>
                        </td> */}
                        <td data-label={firstColumnTitle}>
                          {element.biometric_member_id}
                        </td>
                        <td data-label={secondColumnTitle}>
                          <div className="d-flex align-items-center border-0">
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                              <div className="symbol-label">
                                <img
                                  src={
                                    element.vPhoto
                                      ? `${baseURL}${element.vPhoto}`
                                      : Dummy
                                  }
                                  width={50}
                                  height={50}
                                  className="w-100"
                                />
                              </div>
                            </div>

                            <div className="d-flex flex-column">
                              {element.name}

                              <span>
                                <a
                                  href={`tel:${element.countryCode + element.mobileNo
                                    }`}
                                >
                                  ({element.countryCode}-{element.mobileNo})
                                </a>
                              </span>
                            </div>
                          </div>
                        </td>
                        {/* <td data-label={secondColumnTitle}></td> */}
                        {/* <td data-label={twelthColumnTitle}>
                            {element.vPhoto && (
                              <img
                                src={`${baseURL}${element.vPhoto}`}
                                width={50}
                                height={50}
                              />
                            )}
                          </td> */}
                        {/* <td data-label={thirdColumnTitle}>
                            <a
                              href={`tel:${
                                element.countryCode + element.mobileNo
                              }`}
                            >
                              {element.countryCode}-{element.mobileNo}
                            </a>
                          </td> */}
                        <td data-label={fourthColumnTitle}>
                          {element.eGender}
                        </td>
                        <td data-label={fifthColumnTitle}>
                          {element.employeeName}
                        </td>
                        <td data-label={sixthColumnTitle}>
                          {element.packageName}
                        </td>
                        {/* <td data-label={packageFee}>{element.payableFee}</td>
                        <td data-label={DueAmount}>{element.dueAmt}</td> */}
                        <td data-label={seventhColumnTitle}>
                          {element.batchName}
                        </td>

                        <td data-label={eigthColumnTitle}>
                          {moment(element.dtStartingdate).format(dateFormat)}
                        </td>
                        <td data-label={ninthColumnTitle}>
                          {moment(element.dtExpirydate).format(dateFormat)}
                        </td>
                        {/* <td data-label={tenthColumnTitle}>
                          {StatusBadge(element.eStatus)}
                        </td> */}
                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.ACTIONS",
                          })}
                        >
                          <button className='btn btn-primary'>send</button>
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                {/* <th className=" mobilewidth">
                  <div className="mobileViewalign w-100">
                    <div className=" selectAll ">
                      {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                    </div>
                    <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                        data-kt-check="true"
                        data-kt-check-target=".widget-9-check"
                        checked={isAllRowsChecked}
                        onChange={(e) => handleSelectAllCheckboxChange(e)}
                      />
                    </div>
                  </div>
                </th> */}
                <th className="min-w-50px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="biometric_member_id"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "biometric_member_id" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "biometric_member_id" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "biometric_member_id" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {secondColumnTitle}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                    {twelthColumnTitle}
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mobileNo"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "mobileNo" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mobileNo" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mobileNo" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eGender"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "eGender" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eGender" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eGender" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="employeeName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "employeeName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "employeeName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "employeeName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="payableFee"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {packageFee}
                    {sortConfig.key !== "payableFee" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "payableFee" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "payableFee" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dueAmt"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {DueAmount}
                    {sortConfig.key !== "dueAmt" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dueAmt" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dueAmt" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="batchName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "batchName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "batchName" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtStartingdate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "dtStartingdate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtExpirydate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "dtExpirydate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {tenthColumnTitle}
                    {sortConfig.key !== "eStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "asc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${sortConfig.key == "eStatus" &&
                            sortConfig.direction == "desc"
                            ? "d-block text-primary fw-bold fs-5"
                            : "d-none"
                            }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  {eleventhColumnTitle}
                </th>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin:: pagination  */}
        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  )
}

export default TodayMemberBirthdayModal