import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_RENEWALMEMBERS = `${API_URL}/v1/member/expiredlist`;

export const UPDATE_ADDON_STATUS = `${API_URL}/v1/memberrenewal/update_status`;
export const ACTIVE_MEMBERS_LIST = `${API_URL}/v1/member/listbyBranch`;

export const ACTIVE_TAX = `${API_URL}/v1/tax/listbyBranch`;
export const DELETE_MEMBER = `${API_URL}/v1/memberrenewal/delete`;
export const ADDON_MEMBER_DETAILS = `${API_URL}/v1/memberaddon/details`;

export const RENEWAL_MEMBER_LIST = `${API_URL}/v1/memberaddon/listbyBranch`;
export const BATCH_BYGROUP_LIST = `${API_URL}/v1/batch/listbypackagegroup`;
export const RENEWAL_MEMBER_PACKAGES = `${API_URL}/v1/memberrenewal/getmembershiplist`;
export const RENEWAL_MEMBER_Details = `${API_URL}/v1/memberrenewal/getEnrolledddetails`;
export const RENEWAL_MEMBER_SUBMIT = `${API_URL}/v1/memberrenewal/add`;
export const UPDATE_RENEWAL_MEMBER_SUBMIT = `${API_URL}/v1/memberrenewal/update`;

export const MEMBERSHIP_RENEWAL_MEMBER = `${API_URL}/v1/member/listbyBranch`;
//create user

export function Renewal(data: any) {
  console.log("dataAdd", RENEWAL_MEMBER_SUBMIT);
  return axios.post(RENEWAL_MEMBER_SUBMIT, data).catch((error) => {
    return error.response;
  });
}
export function updateRenewal(data: any) {
  console.log("dataAdd", RENEWAL_MEMBER_SUBMIT);
  return axios.put(UPDATE_RENEWAL_MEMBER_SUBMIT, data).catch((error) => {
    return error.response;
  });
}
export function DeleteMember(body: any) {
  return axios.patch(DELETE_MEMBER, body).catch((error) => {
    return error.response;
  });
}
//

//getting all users
export function GetMembers(payloadData: any) {
  return axios.post(GET_ALL_RENEWALMEMBERS, payloadData).catch((error) => {
    return error.response;
  });
}
//getting single user

//updating user

// updating user status
export function UpdateStatus(data: any) {
  return axios.patch(UPDATE_ADDON_STATUS, data);
}
export function activeUserList(branchUID: any) {
  return axios.get(`${MEMBERSHIP_RENEWAL_MEMBER}/${branchUID}`);
}
export function ActiveMemberList(branchUUID: any) {
  return axios.get(`${ACTIVE_MEMBERS_LIST}/${branchUUID}`);
}

export function ActiveTax(branchUUID: any) {
  return axios.get(`${ACTIVE_TAX}/${branchUUID}`);
}
export function batchIDAPI(packageUUID: any) {
  return axios.get(`${ACTIVE_TAX}/${packageUUID}`);
}
export function GetMemberDetailsRenewal(branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_LIST}/${branchUID}`);
}
export function enrollListofRenewal(branchID: any, branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_PACKAGES}/${branchID}/${branchUID}`);
}
export function enrollDetailsofRenewal(branchID: any, branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_Details}/${branchID}/${branchUID}`);
}

export function GetAddOnMemberDetails(branchID: any, spotUUID: any) {
  return axios.get(`${ADDON_MEMBER_DETAILS}/${branchID}/${spotUUID}`);
}
export function batchIDAPII(ProgramgrpUUID: any) {
  return axios.get(`${BATCH_BYGROUP_LIST}/${ProgramgrpUUID}`);
}
