import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetPlans: '[GetPlans] Action',
}

const initialAuthState: IPlanState = {
  plans: undefined,
}

export interface IPlanState {
  plans?: any
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', blacklist: ['plans']},
  (state: IPlanState = initialAuthState, action: ActionWithPayload<IPlanState>) => {
    switch (action.type) {
      case actionTypes.GetPlans: {
        const plans = action.payload?.plans
        return {...state, plans}
      }
      default:
        return state
    }
  }
)

export const actions = {
  GetPlans: (plans: string) => ({type: actionTypes.GetPlans, payload: {plans}}),
}
