// import React, { useState, useEffect } from "react";
// import { Button, Modal } from "react-bootstrap";
// import { toast } from "react-toastify";
// import ShowplanDetail from "./ShowplanDetail";

// const PlanListmodel = ({
//   plan,
//   setshowPlanlist,
//   showPlanlist,
//   selectedPlanID,
//   setSelectedPlanID,
//   handlePlanClick,
//   handlePlanDataModalOpen,
//   setPlanData,
//   SetshowPlan,
//   currencySymbol,
//   from,
//   setFrom,
// }: any) => {
//   //temp selected plan
//   const [tempSelectedPaln, setTempSelectedPaln] = useState(null);
//   const [checked, setChecked] = useState<any>(false);
//   const [selectedPlanData, setSelectedEnqData] = useState<any>();
//   //   useEffect(() => {
//   //     setTempSelectedPaln(null);
//   //     console.log("log");

//   //   })

//   const handleChange = (Id: any) => {
//     if (Id === selectedPlanID) {
//       return; // If the same plan is selected, do nothing
//     }
//     const result = plan?.find((item: any) => Id === item?.iplanID);
//     setSelectedPlanID(result?.iplanID); // Set selected Plan ID
//     setSelectedEnqData(result); // Set selected plan data
//     setTempSelectedPaln(result); // Update temp selected plan
//     handlePlanClick(Id, result?.price); // Trigger the plan click handler
//   };
//   const sortedPlans: any = plan?.sort((a: any, b: any) => {
//     // Move the selected plan to the top
//     return a?.iplanID === selectedPlanID
//       ? -1
//       : b?.iplanID === selectedPlanID
//       ? 1
//       : 0;
//   });
//   return (
//     <Modal
//       show={showPlanlist}
//       onHide={() => {
//         setshowPlanlist(false);
//       }}
//       size="xl"
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Plans</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {/* start::Plan secion  */}
//         {/* <div className="modal-dialog-scrollable">
//           {plan != null ? (
//             <div className="row plan-listing px-7">
//               {plan?.map((element: any) => {
//                 console.log(element, "jkjkjkjkjkj");
//                 return (
//                   <div
//                     className={`col-md-12 mb-4 plan-card ${
//                       selectedPlanID === element?.iplanID ? "selected" : ""
//                     }`}
//                     key={element?.iplanID}
//                     onClick={() => {
//                       setTempSelectedPaln(element);
//                       handlePlanClick(element.iplanID, element.price);
//                     }}
//                   >
//                     <div className="card mb-2">
//                       <div className="card-body">
//                         <div className="row">
//                           <div className="col-1">
//                             <span className="form-check form-check-custom form-check-solid">
//                               <input
//                                 type="radio"
//                                 className="form-check-input"
//                                 checked={
//                                   selectedPlanID === element?.iplanID
//                                     ? true
//                                     : false
//                                 }
//                               />
//                             </span>
//                           </div>
//                           <div className="col-9">
//                             <h5 className="card-title plan-title">
//                               {element?.membershipTitle}
//                             </h5>
//                             <p className="card-text plan-price">
//                               Price: {element?.price}
//                             </p>
//                           </div>
//                           <div className="col-2">
//                             <div className="plan-button">
//                               <span
//                                 className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
//                                 onClick={() => {
//                                   handlePlanDataModalOpen(element);
//                                 }}
//                               >
//                                 <i className="bi bi-eye"></i>
//                               </span>
//                             </div>
//                           </div>
//                         </div>

//                         <p className="card-text plan-description">
//                           Description: {element?.description}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           ) : (
//             <>
//               {" "}
//               <h1>Please Select any role then search for plan </h1>
//             </>
//           )}
//         </div> */}
//         <div
//           className="row mt-10 "
//           // style={{
//           //   height: "70vh",
//           //   overflow: "scroll",
//           // }}
//         >
//           {/*begin::Col*/}
//           <div
//             className="col-lg-6 mb-10 mb-lg-0 overflow-scroll"
//             style={{
//               height: "60vh",
//               overflow: "scroll",
//             }}
//           >
//             {/*begin::Tabs*/}
//             <div className="nav flex-column ">
//               {/*begin::Tab link*/}
//               {sortedPlans?.map((item: any) => {
//                 return (
//                   <div
//                     className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark  d-flex flex-stack text-start  mb-6 ${
//                       selectedPlanID === item?.iplanID ? " activePlan " : ""
//                     }`}
//                     data-bs-toggle="tab"
//                     data-bs-target="#kt_upgrade_plan_startup"
//                     onClick={() => handleChange(item?.iplanID)}
//                   >
//                     {/*end::Description*/}
//                     <div className="d-flex align-items-center me-2">
//                       {/*begin::Radio*/}
//                       <div className="form-check form-check-custom form-check-solid form-check-success me-6">
//                         <input
//                           className="form-check-input"
//                           type="radio"
//                           name="plan"
//                           checked={
//                             selectedPlanID === item?.iplanID ? true : false
//                           }
//                           value={checked}
//                         />
//                       </div>
//                       {/*end::Radio*/}
//                       {/*begin::Info*/}
//                       <div className="flex-grow-1">
//                         <h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">
//                           {item?.membershipTitle}
//                         </h2>
//                         {/* <div className="fw-bold opacity-50">
//                           {item?.description}
//                         </div> */}
//                       </div>
//                       {/*end::Info*/}
//                     </div>
//                     {/*end::Description*/}
//                     {/*begin::Price*/}
//                     <div className="ms-5">
//                       <span className="mb-2 text-success fs-2x me-1">
//                         {currencySymbol}
//                       </span>
//                       <span
//                         className="fs-3x fw-bolder"
//                         data-kt-plan-price-month="39"
//                         data-kt-plan-price-annual="399"
//                       >
//                         {item?.price}
//                       </span>
//                       <span className="fs-7 opacity-50">
//                         /
//                         <span data-kt-element="period">
//                           {item?.days}
//                           <span className="ms-1">days</span>
//                         </span>
//                       </span>
//                     </div>
//                     {/*end::Price*/}
//                   </div>
//                 );
//               })}
//               {/*end::Tab link*/}

//               {/*end::Tab link*/}
//             </div>
//             {/*end::Tabs*/}
//           </div>
//           {/*end::Col*/}
//           {/*begin::Col*/}
//           <div className="col-lg-6 px-2">
//             {/*begin::Tab content*/}
//             <ShowplanDetail selectedPlan={selectedPlanData} />
//             {/*end::Tab content*/}
//           </div>
//           {/*end::Col*/}
//         </div>

//         {/* end::Plan secion  */}
//       </Modal.Body>
//       <Modal.Footer>
//         <Button
//           variant="btn btn-primary"
//           onClick={() => {
//             if (selectedPlanID === null) {
//               toast.error("Please Select any Plan to Confirm");
//               return;
//             }
//             setPlanData(tempSelectedPaln);
//             SetshowPlan(true);
//             setshowPlanlist(false);
//           }}
//         >
//           Confirm
//         </Button>
//         <Button
//           variant="secondary"
//           onClick={() => {
//             //setPlanData(null)
//             setshowPlanlist(false);
//           }}
//         >
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default PlanListmodel;
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ShowplanDetail from "./ShowplanDetail";

const PlanListmodel = ({
  plan,
  setshowPlanlist,
  showPlanlist,
  selectedPlanID,
  setSelectedPlanID,
  handlePlanClick,
  handlePlanDataModalOpen,
  setPlanData,
  SetshowPlan,
  currencySymbol,
  extraData,
  from,
  setFrom, // New prop to control sorting
}: any) => {
  const [tempSelectedPlan, setTempSelectedPlan] = useState<any>();
  const [checked, setChecked] = useState<any>(false);
  const [selectedPlanData, setSelectedEnqData] = useState<any>(
    plan?.[0]?.iplanID
  );
  const [NotModule, setNotModule] = useState<any>([]);
  const [sortedPlans, setSortedPlans] = useState<any[]>(plan); // Maintain a sorted list

  const getModules = () => {
    const unselectedModules = extraData?.allModule?.filter((module: any) => {
      // console.log(module, "filterfunction");
      return !tempSelectedPlan?.modules?.some((selected: any) => {
        return selected.iAdminModuleID === module.iAdminModuleID;
      });
    });
    // console.log(unselectedModules, "dsljkjlkfd");
    setNotModule(unselectedModules);
  };
  useEffect(() => {
    getModules();
  }, [extraData, plan, tempSelectedPlan]);
  useEffect(() => {
    // Sort the plans only if "sortPlansOnOpen" is true (when entered from the change plan button)
    if (from) {
      const sorted = plan?.sort((a: any, b: any) => {
        if (a?.iplanID === selectedPlanID) return -1;
        if (b?.iplanID === selectedPlanID) return 1;
        return 0;
      });
      setSortedPlans(sorted);
      setFrom(false);
    } else {
      // If not sorting, use the original plan list
      setSortedPlans(plan);
      setFrom(false);
    }
  }, [plan, selectedPlanID, from]);

  useEffect(() => {
    if (plan?.length > 0) {
      const firstPlan = plan[0]; // Get the first plan from the list
      setSelectedPlanID(firstPlan?.iplanID); // Set the initial plan ID
      setSelectedEnqData(firstPlan); // Set the initial plan data
      setTempSelectedPlan(firstPlan); // Set the temp selected plan
      handlePlanClick(
        firstPlan?.iplanID,
        currencySymbol === "$"
          ? (firstPlan?.price / extraData?.currentDollarPriceInINR)?.toFixed(2)
          : firstPlan?.price
      );
    }
  }, [plan]);

  const handleChange = (Id: any) => {
    if (Id === selectedPlanID) {
      return; // If the same plan is selected, do nothing
    }
    const result = plan?.find((item: any) => Id === item?.iplanID);
    setSelectedPlanID(result?.iplanID); // Set selected Plan ID
    setSelectedEnqData(result); // Set selected plan data
    setTempSelectedPlan(result); // Update temp selected plan
    handlePlanClick(
      Id,
      currencySymbol === "$"
        ? (result?.price / extraData?.currentDollarPriceInINR)?.toFixed(2)
        : result?.price
    ); // Trigger the plan click handler
  };

  return (
    <Modal
      show={showPlanlist}
      onHide={() => {
        setshowPlanlist(false);
      }}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Plans</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-10">
          <div
            className="col-lg-6 mb-10 mb-lg-0 overflow-scroll"
            style={{
              height: "60vh",
              overflow: "scroll",
            }}
          >
            <div className="nav flex-column">
              {sortedPlans?.map((item: any) => {
                return (
                  <div
                    className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark  d-flex flex-stack text-start  mb-6 ${
                      selectedPlanID === item?.iplanID ? " activePlan " : ""
                    }`}
                    data-bs-toggle="tab"
                    data-bs-target="#kt_upgrade_plan_startup"
                    onClick={() => handleChange(item?.iplanID)}
                  >
                    <div className="d-flex align-items-center me-2">
                      <div className="form-check form-check-custom form-check-solid form-check-success me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan"
                          checked={
                            selectedPlanID === item?.iplanID ? true : false
                          }
                          value={checked}
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">
                          {item?.membershipTitle}
                        </h2>
                      </div>
                    </div>
                    <div className="ms-5">
                      <span className="mb-2 text-success fs-2x me-1">
                        {currencySymbol}
                      </span>
                      <span className="fs-3x fw-bolder">
                        {currencySymbol === "$"
                          ? (
                              item?.price / extraData?.currentDollarPriceInINR
                            )?.toFixed(2)
                          : item?.price}
                      </span>
                      <span className="fs-7 opacity-50">
                        /{item?.days} <span className="ms-1">days</span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-6 px-2">
            <ShowplanDetail
              selectedPlan={selectedPlanData}
              NotModule={NotModule}
              currencySymbol={currencySymbol}
              extraData={extraData}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="btn btn-primary"
          onClick={() => {
            if (selectedPlanID === null) {
              toast.error("Please Select any Plan to Confirm");
              return;
            }
            setPlanData(tempSelectedPlan);
            SetshowPlan(true);
            setshowPlanlist(false);
          }}
        >
          Confirm
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setshowPlanlist(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanListmodel;
