// import {Action} from '@reduxjs/toolkit'
// import {persistReducer} from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// //import {getUserByToken} from './AuthCRUD'

// export interface ActionWithPayload<T> extends Action {
//   payload?: T
// }


// // Updated ActionTypes Object
// export const actionTypes = {
//     GetEmployees: '[GetEmployees] Action',
//   }
  
//   // Updated Initial State
//   const initialAuthState: IEmployeeState = {
//     employees: undefined,
//   }
  
//   // Updated Department State Interface
//   export interface IEmployeeState {
//     employees?: any
//   }
  
//   // Updated Reducer Function
//   export const reducer = persistReducer(
//     { storage, key: 'v100-demo1-auth', blacklist: ['employees'] },
//     (state: IEmployeeState = initialAuthState, action: ActionWithPayload<IEmployeeState>) => {
//       switch (action.type) {
//         case actionTypes.GetEmployees: {
//           const employees = action.payload?.employees
//           return { ...state, employees }
//         }
//         default:
//           return state
//       }
//     }
//   )
  
//   // Updated Actions Object
//   export const actions = {
//     GetEmployees: (employees:any) => ({ type: actionTypes.GetEmployees, payload: { employees } }),
//   }
import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}


// Updated ActionTypes Object
export const actionTypes = {
    GetDepartments: '[GetDepartments] Action',
  }
  
  // Updated Initial State
  const initialAuthState: IDepartmentState = {
    departments: undefined,
  }
  
  // Updated Department State Interface
  export interface IDepartmentState {
    departments?: any
  }
  
  // Updated Reducer Function
  export const reducer = persistReducer(
    { storage, key: 'v100-demo1-auth', blacklist: ['departments'] },
    (state: IDepartmentState = initialAuthState, action: ActionWithPayload<IDepartmentState>) => {
      switch (action.type) {
        case actionTypes.GetDepartments: {
          const departments = action.payload?.departments
          return { ...state, departments }
        }
        default:
          return state
      }
    }
  )
  
  // Updated Actions Object
  export const actions = {
    GetDepartments: (departments:any) => ({ type: actionTypes.GetDepartments, payload: { departments } }),
  }
  