import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetAllLanguages: "[GetAllLanguages] Action",
  GetLanguageFile: "[GetLanguageFile] Action",
  SetLanguageJson: "[SetLanguageJson] Action",
  SetLanguage: "[SetLanguage] Action",
};

const initialAuthState: ICenterState = {
  languageList: undefined,
  selectedlanguages: undefined,
  languagejson: undefined,
  selectedLang: "English",
};

export interface ICenterState {
  languageList?: any;
  selectedlanguages?: any;
  languagejson?: any;
  selectedLang?: any;
}

export const reducer = persistReducer(
  {
    storage,
    key: "v100-language-auth",
    whitelist: ["languageList", "selectedlanguages"],
  },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    // console.log('action',action)
    switch (action.type) {
      case actionTypes.GetAllLanguages: {
        const languageList = action.payload?.languageList;
        return { ...state, languageList };
      }
      case actionTypes.GetLanguageFile: {
        const selectedlanguages = action.payload?.selectedlanguages;
        return { ...state, selectedlanguages };
      }
      case actionTypes.SetLanguageJson: {
        const languagejson = action.payload?.languagejson;
        return { ...state, languagejson };
      }
      case actionTypes.SetLanguage: {
        const selectedLang = action.payload;
        console.log(selectedLang, "payload");
        // console.log(action.payload, "here is the lang set");
        return { selectedLang };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetAllLanguages: (languageList: any) => ({
    type: actionTypes.GetAllLanguages,
    payload: { languageList },
  }),
  GetLanguageFile: (selectedlanguages: any) => ({
    type: actionTypes.GetLanguageFile,
    payload: { selectedlanguages },
  }),
  SetLanguageFile: (languagejson: any) => ({
    type: actionTypes.SetLanguageJson,
    payload: { languagejson },
  }),
  SetLanguage: (lang: any) => ({
    type: actionTypes.SetLanguage,
    payload: { lang },
  }),
};
