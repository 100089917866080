import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const DETAIL_COMPANY = `${API_URL}/v1/setting/details`;
export const UPDATE_PATH = `${API_URL}/v1/setting/update/`;
export const CURRENCY_PATH = `${API_URL}/v1/common/countryandcourencylist`;
export const BIOMETRIC_COMPANYLIST = `${API_URL}/v1/common/biometriccompanylist/en`;
export const TIMEZONE = `${API_URL}/v1/common/timezonelist`;

export function detailsOfCompanyApi(companyUUID: any) {
  return axios.get(`${DETAIL_COMPANY}/${companyUUID}`).catch((error) => {
    return error.response;
  });
}
export function currencyDataApi() {
  return axios.get(CURRENCY_PATH).catch((error) => {
    return error.response;
  });
}
export function BiometricCompanyOption() {
  return axios.get(BIOMETRIC_COMPANYLIST).catch((error) => {
    return error.response;
  });
}

export function UpdateCompany(data: any) {
  return axios.put(UPDATE_PATH, data).catch((err) => {
    return err.response;
  });
}
export function TimeZoneChange() {
  return axios.get(TIMEZONE).catch((err) => {
    return err.response;
  });
}
