import { useState } from "react";
import Select from "react-select";
// import { Link } from "react-router-dom";
// import { Languages } from "./Languages";
import { useDispatch } from "react-redux";
import { actions as authActions } from "../../../../app/modules/auth/redux/AuthRedux";
// import { option } from "yargs";
import { GetActiveBranch } from "../../../../app/modules/auth/redux/AuthCRUD";
import { useHistory } from "react-router-dom";

const HeaderBranchMenu = () => {
  const history = useHistory();
  let data: any = localStorage?.getItem("ActiveBranchList");

  const branches = JSON.parse(data);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const dispatch = useDispatch();

  const handleBranchClick = async (Event: any) => {
    // setSelectedBranch(branch);
    setSelectedBranch({ value: Event.value, label: Event.label });
    const branch = branches.find(
      (item: any) => item.iBranchUUID === Event.value
    );
    // console.log(branches, "branchessd");
    dispatch(authActions.selectBranch(branch));
    const response = await GetActiveBranch(branch);
    // console.log(response, "brahchhes");
    localStorage.setItem("ActiveBranch", JSON.stringify(response?.data?.data));
    history.push("/");
    // window.location.reload();
  };
  //localStorage.setItem('ActiveBranch',JSON.stringify(selectedBranch))

  // console.log("branches;;;;;;;", branches);

  //console.log(branches,"!!!!!!!!!!!!!@@@@@Active brnach")

  // let activeuuid:any=localStorage.getItem('ActiveBranchUUID')
  // const activeBranchUUID=JSON.parse(activeuuid)

  // function getOptionLabel(option: any) {
  //   if (typeof option === "string") {
  //     return "";
  //   }
  //   const label = `${option?.vBranch}`;
  //   return label;
  // }

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
      style={{
        padding: "15px", // Increase padding
      }}
    >
      <div className="separator my-2"></div>
      <Select
        value={selectedBranch}
        onChange={(option) => handleBranchClick(option)}
        // options={branches}
        isSearchable
        options={branches?.map((item: any) => {
          // console.log(item, "itemsdj");
          return { value: item.iBranchUUID, label: item.vBranch };
        })}
        // getOptionLabel={(option) => getOptionLabel(option)}
        className="`menu-item px-5 py-3 basic-single"
        // getOptionValue={branches?.iBranchUUID}
        classNamePrefix="select"
      />

      <div className="separator my-2"></div>

      {/* Your other menu items go here */}
      {/* Example: */}
    </div>
  );
};

export default HeaderBranchMenu;
