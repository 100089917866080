import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetBranches: '[GetBranches] Action',
}

const initialAuthState: ICenterState = {
  branches: undefined,
}

export interface ICenterState {
  branches?: any
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', blacklist: ['branches']},
  (state: ICenterState = initialAuthState, action: ActionWithPayload<ICenterState>) => {
    switch (action.type) {
      case actionTypes.GetBranches: {
        const branches = action.payload?.branches
        return {...state, branches}
      }

      default:
        return state
    }
  }
)

export const actions = {
  GetBranches: (branches: string) => ({type: actionTypes.GetBranches, payload: {branches}}),
}
