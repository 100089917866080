import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetRoles: '[GetRoles] Action',
  GetRolesPermission: '[GetRolesPermission] Action',
  GetRolesPermissionBYID: '[GetRolesPermissionBYID] Action',
}

const initialAuthState: IRoleState = {
  roles: undefined,
  rolesPermission: undefined,
  rolesPermissionBYID: undefined,
}

export interface IRoleState {
  roles?: any
  rolesPermission?: any
  rolesPermissionBYID?: any
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', blacklist: ['roles']},
  (state: IRoleState = initialAuthState, action: ActionWithPayload<IRoleState>) => {
    switch (action.type) {
      case actionTypes.GetRoles: {
        const roles = action.payload?.roles
        return {...state, roles}
      }
      case actionTypes.GetRolesPermission: {
        const rolesPermission = action.payload
        return {...state, rolesPermission}
      }
      case actionTypes.GetRolesPermissionBYID: {
        const rolesPermissionBYID = action.payload
        return {...state, rolesPermissionBYID}
      }
      default:
        return state
    }
  }
)

export const actions = {
  GetRoles: (roles: string) => ({type: actionTypes.GetRoles, payload: {roles}}),
  GetRolesPermission: (rolesPermission: string) => {
    return {type: actionTypes.GetRolesPermission, payload: rolesPermission}
  },
  GetRolesPermissionBYID: (rolesPermissionBYID: string) => {
    return {type: actionTypes.GetRolesPermissionBYID, payload: rolesPermissionBYID}
  }
}


