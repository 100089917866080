import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EmailDesign.css";
// import isEqual from "lodash/isEqual";
// import Select from "react-select";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

import { UpdateExpenseCategory } from "../../redux/EmailCRUD";

const EditEmail = ({
  show,
  setShow,
  getExpenseCategory, // Replace with your function to fetch departments
  UpdateUser,
  setUpdateUser,
  isUpdate,
  setisUpdate,
  error,
  setError,
}: any) => {
  //   const branches = useSelector((state: any) => state?.auth?.branches);
  const intl = useIntl();

  let activeBranch: any = localStorage.getItem("ActiveBranch");
  const selectedActiveBranch = JSON.parse(activeBranch);

  const UpdateValue = {
    branchID: UpdateUser?.iBranchID || "",
    subject: UpdateUser?.subject || "",
    status: UpdateUser?.eStatus || "",
    EMAILTemplateUUID: UpdateUser?.emailUserTemplateUUID || "",
    // dltTemplateID: UpdateUser?.dlt_template_id,
    messageTYPE: UpdateUser?.mesaageType || "",
    message: UpdateUser?.vMessage || "",
  };
  const stripHtmlTags = (html: any) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: UpdateValue,
    validationSchema: Yup.object().shape({
      branchID: Yup.string().required(
        `${intl.formatMessage({ id: "MODULE.BRANCH.REQUIRED" })}`
      ),
      subject: Yup.string().required(
        `${intl.formatMessage({ id: "REQUIRE.MODULE.SUBJECT" })}`
      ),
      message: Yup.string()
        .test(
          "is-not-empty",
          intl.formatMessage({ id: "REQUIRE.MODULE.MESSAGE" }),
          (value) => stripHtmlTags(value).trim().length > 0
        )
        .required(intl.formatMessage({ id: "REQUIRE.MODULE.MESSAGE" })), // Changed from departName to department_name
      status: Yup.string().required(
        `${intl.formatMessage({ id: "COMMON.STATUS.REQUIRED" })}`
      ),
    }),
    onSubmit: async (values) => {
      // console.log("values", values);

      const response = await UpdateExpenseCategory(values);
      if (response.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
        formik.resetForm();
        setShow(false);
        getExpenseCategory(); // Fetch departments after updating
        setError();
      } else {
        setError(response.data.replyMsg);
      }
    },
  });
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        formik.resetForm();
        setisUpdate(false);
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isUpdate
            ? `${intl.formatMessage({
                id: "MODULE.EXPENSECATEGORY.UPDATEBUTTON",
              })}`
            : `${intl.formatMessage({
                id: "MODULE.EXPENSECATEGORY.ADD",
              })}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">
              {intl.formatMessage({ id: "MODULE.EMAIL.SUBJECT" })}
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder={intl.formatMessage({
                id: "MODULE.SMS.MESSAGE.TEMPID",
              })}
              className="form-control"
              onChange={formik.handleChange("subject")}
              onBlur={formik.handleBlur("subject")}
              value={formik.values.subject}
            />
            {formik.errors.subject ? (
              <div className="text-danger"> {formik.errors.subject}</div>
            ) : (
              error && <div className="text-danger">{error}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="messageTYPE" className="form-label">
              {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.TYPE" })}
            </label>
            <input
              type="text"
              id="mesaageType"
              name="messageTYPE"
              placeholder={intl.formatMessage({
                id: "MODULE.SMS.MESSAGE.TEMPID",
              })}
              className="form-control"
              value={formik.values.messageTYPE}
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.MESSAGE" })}
            </label>
            <ReactQuill
              id="message"
              value={formik.values.message}
              className="form-control"
              onChange={(value) => formik.setFieldValue("message", value)}
              onBlur={() => formik.setFieldTouched("message", true)}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
            />
            {/* <CustomEditor
              value={formik.values.message}
              onChange={(value: any) => formik.setFieldValue("message", value)}
              onBlur={() => formik.setFieldTouched("message", true)}
            /> */}

            {formik.touched.message && formik.errors.message && (
              <div className="text-danger"> {formik.errors.message}</div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="status" className="form-label">
              {intl.formatMessage({ id: "COMMON.STATUS" })}
            </label>
            <select
              id="status"
              name="status"
              className="form-select"
              onChange={(event) => {
                formik.handleChange(event); // formik.handleChange will handle setting the field value
              }}
              onBlur={formik.handleBlur("status")}
              value={formik.values.status}
            >
              <option value="1">
                {intl.formatMessage({ id: "COMMON.ACTIVE" })}
              </option>
              <option value="0">
                {intl.formatMessage({ id: "COMMON.INACTIVE" })}
              </option>
              {/* <option value="2">
                {intl.formatMessage({ id: "COMMON.DELETE" })}
              </option> */}
            </select>

            {/*  {formik.touched.status && formik.errors.status && (
              <div className="text-danger">{formik.errors.status}</div>
            )} */}
          </div>

          {/*      <div className="text-danger">{error}</div>
           */}
          <button type="submit" className="btn btn-primary">
            {isUpdate
              ? `${intl.formatMessage({
                  id: "MODULE.EXPENSECATEGORY.UPDATEBUTTON",
                })}`
              : `${intl.formatMessage({
                  id: "MODULE.EXPENSECATEGORY.SAVEBUTTON",
                })}`}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditEmail;
